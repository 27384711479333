import { emptyAiqApi as api } from "./aiq-api-empty";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCompoundInterscanAnalysesByIdScanAnalysisViewModels: build.query<
      GetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsApiResponse,
      GetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalyses/${queryArg.id}/ScanAnalysisViewModels`,
      }),
    }),
    postApiCompoundInterscanAnalysesCreateAndInitialize: build.mutation<
      PostApiCompoundInterscanAnalysesCreateAndInitializeApiResponse,
      PostApiCompoundInterscanAnalysesCreateAndInitializeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalyses/CreateAndInitialize`,
        method: "POST",
        body: queryArg.compoundInterscanAnalysisCreateAndInitializeRequest,
      }),
    }),
    postApiCompoundInterscanAnalyses: build.mutation<
      PostApiCompoundInterscanAnalysesApiResponse,
      PostApiCompoundInterscanAnalysesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalyses`,
        method: "POST",
        body: queryArg.compoundInterscanAnalysis,
      }),
    }),
    getApiCompoundInterscanAnalyses: build.query<
      GetApiCompoundInterscanAnalysesApiResponse,
      GetApiCompoundInterscanAnalysesApiArg
    >({
      query: () => ({ url: `/api/CompoundInterscanAnalyses` }),
    }),
    putApiCompoundInterscanAnalysesById: build.mutation<
      PutApiCompoundInterscanAnalysesByIdApiResponse,
      PutApiCompoundInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalyses/${queryArg.id}`,
        method: "PUT",
        body: queryArg.compoundInterscanAnalysis,
      }),
    }),
    deleteApiCompoundInterscanAnalysesById: build.mutation<
      DeleteApiCompoundInterscanAnalysesByIdApiResponse,
      DeleteApiCompoundInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalyses/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiCompoundInterscanAnalysesById: build.query<
      GetApiCompoundInterscanAnalysesByIdApiResponse,
      GetApiCompoundInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalyses/${queryArg.id}`,
      }),
    }),
    getApiCompoundInterscanAnalysisViewModelsById: build.query<
      GetApiCompoundInterscanAnalysisViewModelsByIdApiResponse,
      GetApiCompoundInterscanAnalysisViewModelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalysisViewModels/${queryArg.id}`,
      }),
    }),
    getApiCompoundInterscanApplications: build.query<
      GetApiCompoundInterscanApplicationsApiResponse,
      GetApiCompoundInterscanApplicationsApiArg
    >({
      query: () => ({ url: `/api/compoundInterscanApplications` }),
    }),
    getApiConfiguration: build.query<
      GetApiConfigurationApiResponse,
      GetApiConfigurationApiArg
    >({
      query: () => ({ url: `/api/configuration` }),
    }),
    getApiConfigurationBuildInfo: build.query<
      GetApiConfigurationBuildInfoApiResponse,
      GetApiConfigurationBuildInfoApiArg
    >({
      query: () => ({ url: `/api/configuration/BuildInfo` }),
    }),
    postApiExamsByIdScans: build.mutation<
      PostApiExamsByIdScansApiResponse,
      PostApiExamsByIdScansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/${queryArg.id}/scans`,
        method: "POST",
        body: queryArg.scan,
      }),
    }),
    getApiExamsByIdScans: build.query<
      GetApiExamsByIdScansApiResponse,
      GetApiExamsByIdScansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/${queryArg.id}/scans`,
        params: {
          modalityId: queryArg.modalityId,
        },
      }),
    }),
    postApiExams: build.mutation<PostApiExamsApiResponse, PostApiExamsApiArg>({
      query: (queryArg) => ({
        url: `/api/Exams`,
        method: "POST",
        body: queryArg.exam,
      }),
    }),
    getApiExams: build.query<GetApiExamsApiResponse, GetApiExamsApiArg>({
      query: () => ({ url: `/api/Exams` }),
    }),
    putApiExamsById: build.mutation<
      PutApiExamsByIdApiResponse,
      PutApiExamsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/${queryArg.id}`,
        method: "PUT",
        body: queryArg.exam,
      }),
    }),
    deleteApiExamsById: build.mutation<
      DeleteApiExamsByIdApiResponse,
      DeleteApiExamsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Exams/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiExamsById: build.query<
      GetApiExamsByIdApiResponse,
      GetApiExamsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Exams/${queryArg.id}` }),
    }),
    getApiIdsPatients: build.query<
      GetApiIdsPatientsApiResponse,
      GetApiIdsPatientsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ids/patients`,
        params: {
          identifier: queryArg.identifier,
          studyId: queryArg.studyId,
        },
      }),
    }),
    getApiIdsStudy: build.query<
      GetApiIdsStudyApiResponse,
      GetApiIdsStudyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ids/study`,
        params: {
          name: queryArg.name,
        },
      }),
    }),
    getApiIdsTimepoints: build.query<
      GetApiIdsTimepointsApiResponse,
      GetApiIdsTimepointsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ids/timepoints`,
        params: {
          name: queryArg.name,
          studyId: queryArg.studyId,
        },
      }),
    }),
    postApiInterscanAnalysesCreateAndInitializeUngrouped: build.mutation<
      PostApiInterscanAnalysesCreateAndInitializeUngroupedApiResponse,
      PostApiInterscanAnalysesCreateAndInitializeUngroupedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses/CreateAndInitializeUngrouped`,
        method: "POST",
        body: queryArg.ungroupedInterscanAnalysisCreateAndInitializeRequest,
      }),
    }),
    postApiInterscanAnalysesCreateAndInitializeGrouped: build.mutation<
      PostApiInterscanAnalysesCreateAndInitializeGroupedApiResponse,
      PostApiInterscanAnalysesCreateAndInitializeGroupedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses/CreateAndInitializeGrouped`,
        method: "POST",
        body: queryArg.groupedInterscanAnalysisCreateAndInitializeRequest,
      }),
    }),
    getApiInterscanAnalysesByIdScanAnalysisViewModels: build.query<
      GetApiInterscanAnalysesByIdScanAnalysisViewModelsApiResponse,
      GetApiInterscanAnalysesByIdScanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses/${queryArg.id}/ScanAnalysisViewModels`,
      }),
    }),
    postApiInterscanAnalyses: build.mutation<
      PostApiInterscanAnalysesApiResponse,
      PostApiInterscanAnalysesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses`,
        method: "POST",
        body: queryArg.interscanAnalysis,
      }),
    }),
    getApiInterscanAnalyses: build.query<
      GetApiInterscanAnalysesApiResponse,
      GetApiInterscanAnalysesApiArg
    >({
      query: () => ({ url: `/api/InterscanAnalyses` }),
    }),
    putApiInterscanAnalysesById: build.mutation<
      PutApiInterscanAnalysesByIdApiResponse,
      PutApiInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses/${queryArg.id}`,
        method: "PUT",
        body: queryArg.interscanAnalysis,
      }),
    }),
    deleteApiInterscanAnalysesById: build.mutation<
      DeleteApiInterscanAnalysesByIdApiResponse,
      DeleteApiInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiInterscanAnalysesById: build.query<
      GetApiInterscanAnalysesByIdApiResponse,
      GetApiInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/InterscanAnalyses/${queryArg.id}` }),
    }),
    getApiInterscanAnalysisViewModelsById: build.query<
      GetApiInterscanAnalysisViewModelsByIdApiResponse,
      GetApiInterscanAnalysisViewModelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalysisViewModels/${queryArg.id}`,
      }),
    }),
    getApiInterscanApplications: build.query<
      GetApiInterscanApplicationsApiResponse,
      GetApiInterscanApplicationsApiArg
    >({
      query: () => ({ url: `/api/interscanApplications` }),
    }),
    getApiPatientsByIdExams: build.query<
      GetApiPatientsByIdExamsApiResponse,
      GetApiPatientsByIdExamsApiArg
    >({
      query: (queryArg) => ({ url: `/api/Patients/${queryArg.id}/Exams` }),
    }),
    postApiPatients: build.mutation<
      PostApiPatientsApiResponse,
      PostApiPatientsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients`,
        method: "POST",
        body: queryArg.patient,
      }),
    }),
    getApiPatients: build.query<
      GetApiPatientsApiResponse,
      GetApiPatientsApiArg
    >({
      query: () => ({ url: `/api/Patients` }),
    }),
    putApiPatientsById: build.mutation<
      PutApiPatientsByIdApiResponse,
      PutApiPatientsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients/${queryArg.id}`,
        method: "PUT",
        body: queryArg.patient,
      }),
    }),
    deleteApiPatientsById: build.mutation<
      DeleteApiPatientsByIdApiResponse,
      DeleteApiPatientsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Patients/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiPatientsById: build.query<
      GetApiPatientsByIdApiResponse,
      GetApiPatientsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Patients/${queryArg.id}` }),
    }),
    getApiPlatformRolesById: build.query<
      GetApiPlatformRolesByIdApiResponse,
      GetApiPlatformRolesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/PlatformRoles/${queryArg.id}` }),
    }),
    getApiPlatformRoles: build.query<
      GetApiPlatformRolesApiResponse,
      GetApiPlatformRolesApiArg
    >({
      query: () => ({ url: `/api/PlatformRoles` }),
    }),
    getApiPlatformUsersSelf: build.query<
      GetApiPlatformUsersSelfApiResponse,
      GetApiPlatformUsersSelfApiArg
    >({
      query: () => ({ url: `/api/PlatformUsers/self` }),
    }),
    getApiPlatformUsersByIdRoles: build.query<
      GetApiPlatformUsersByIdRolesApiResponse,
      GetApiPlatformUsersByIdRolesApiArg
    >({
      query: (queryArg) => ({ url: `/api/PlatformUsers/${queryArg.id}/roles` }),
    }),
    postApiPlatformUsersByIdRoles: build.mutation<
      PostApiPlatformUsersByIdRolesApiResponse,
      PostApiPlatformUsersByIdRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PlatformUsers/${queryArg.id}/roles`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteApiPlatformUsersByIdRolesAndRoleId: build.mutation<
      DeleteApiPlatformUsersByIdRolesAndRoleIdApiResponse,
      DeleteApiPlatformUsersByIdRolesAndRoleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PlatformUsers/${queryArg.id}/roles/${queryArg.roleId}`,
        method: "DELETE",
      }),
    }),
    postApiPlatformUsers: build.mutation<
      PostApiPlatformUsersApiResponse,
      PostApiPlatformUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PlatformUsers`,
        method: "POST",
        body: queryArg.platformUser,
      }),
    }),
    getApiPlatformUsers: build.query<
      GetApiPlatformUsersApiResponse,
      GetApiPlatformUsersApiArg
    >({
      query: () => ({ url: `/api/PlatformUsers` }),
    }),
    putApiPlatformUsersById: build.mutation<
      PutApiPlatformUsersByIdApiResponse,
      PutApiPlatformUsersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PlatformUsers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.platformUser,
      }),
    }),
    deleteApiPlatformUsersById: build.mutation<
      DeleteApiPlatformUsersByIdApiResponse,
      DeleteApiPlatformUsersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PlatformUsers/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiPlatformUsersById: build.query<
      GetApiPlatformUsersByIdApiResponse,
      GetApiPlatformUsersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/PlatformUsers/${queryArg.id}` }),
    }),
    postApiResponseAnalysisReportsGenerate: build.mutation<
      PostApiResponseAnalysisReportsGenerateApiResponse,
      PostApiResponseAnalysisReportsGenerateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ResponseAnalysisReports/generate`,
        method: "POST",
        body: queryArg.responseAnalysisReportCreationRequest,
      }),
    }),
    getApiResponseAnalysisReportsLightViewModels: build.query<
      GetApiResponseAnalysisReportsLightViewModelsApiResponse,
      GetApiResponseAnalysisReportsLightViewModelsApiArg
    >({
      query: () => ({ url: `/api/ResponseAnalysisReports/LightViewModels` }),
    }),
    postApiResponseAnalysisReports: build.mutation<
      PostApiResponseAnalysisReportsApiResponse,
      PostApiResponseAnalysisReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ResponseAnalysisReports`,
        method: "POST",
        body: queryArg.responseAnalysisReport,
      }),
    }),
    getApiResponseAnalysisReports: build.query<
      GetApiResponseAnalysisReportsApiResponse,
      GetApiResponseAnalysisReportsApiArg
    >({
      query: () => ({ url: `/api/ResponseAnalysisReports` }),
    }),
    putApiResponseAnalysisReportsById: build.mutation<
      PutApiResponseAnalysisReportsByIdApiResponse,
      PutApiResponseAnalysisReportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ResponseAnalysisReports/${queryArg.id}`,
        method: "PUT",
        body: queryArg.responseAnalysisReport,
      }),
    }),
    deleteApiResponseAnalysisReportsById: build.mutation<
      DeleteApiResponseAnalysisReportsByIdApiResponse,
      DeleteApiResponseAnalysisReportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ResponseAnalysisReports/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiResponseAnalysisReportsById: build.query<
      GetApiResponseAnalysisReportsByIdApiResponse,
      GetApiResponseAnalysisReportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ResponseAnalysisReports/${queryArg.id}`,
      }),
    }),
    getApiScanAnalysesByScans: build.query<
      GetApiScanAnalysesByScansApiResponse,
      GetApiScanAnalysesByScansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalyses/ByScans`,
        params: {
          anatomicalScanId: queryArg.anatomicalScanId,
          functionalScanId: queryArg.functionalScanId,
        },
      }),
    }),
    getApiScanAnalysesByIdSteps: build.query<
      GetApiScanAnalysesByIdStepsApiResponse,
      GetApiScanAnalysesByIdStepsApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalyses/${queryArg.id}/Steps` }),
    }),
    postApiScanAnalyses: build.mutation<
      PostApiScanAnalysesApiResponse,
      PostApiScanAnalysesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalyses`,
        method: "POST",
        body: queryArg.scanAnalysis,
      }),
    }),
    getApiScanAnalyses: build.query<
      GetApiScanAnalysesApiResponse,
      GetApiScanAnalysesApiArg
    >({
      query: () => ({ url: `/api/ScanAnalyses` }),
    }),
    putApiScanAnalysesById: build.mutation<
      PutApiScanAnalysesByIdApiResponse,
      PutApiScanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalyses/${queryArg.id}`,
        method: "PUT",
        body: queryArg.scanAnalysis,
      }),
    }),
    deleteApiScanAnalysesById: build.mutation<
      DeleteApiScanAnalysesByIdApiResponse,
      DeleteApiScanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalyses/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiScanAnalysesById: build.query<
      GetApiScanAnalysesByIdApiResponse,
      GetApiScanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalyses/${queryArg.id}` }),
    }),
    postApiScanAnalysisStepsByIdTasks: build.mutation<
      PostApiScanAnalysisStepsByIdTasksApiResponse,
      PostApiScanAnalysisStepsByIdTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}/Tasks`,
        method: "POST",
        body: queryArg.scanProcessingTask,
      }),
    }),
    getApiScanAnalysisStepsByIdTasks: build.query<
      GetApiScanAnalysisStepsByIdTasksApiResponse,
      GetApiScanAnalysisStepsByIdTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}/Tasks`,
      }),
    }),
    postApiScanAnalysisStepsByIdTransition: build.mutation<
      PostApiScanAnalysisStepsByIdTransitionApiResponse,
      PostApiScanAnalysisStepsByIdTransitionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}/Transition`,
        method: "POST",
        body: queryArg.scanAnalysisStepTransitionRequest,
      }),
    }),
    getApiScanAnalysisStepsByIdArtifactUrls: build.query<
      GetApiScanAnalysisStepsByIdArtifactUrlsApiResponse,
      GetApiScanAnalysisStepsByIdArtifactUrlsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}/ArtifactUrls`,
      }),
    }),
    postApiScanAnalysisStepsByIdManuallyMarkComplete: build.mutation<
      PostApiScanAnalysisStepsByIdManuallyMarkCompleteApiResponse,
      PostApiScanAnalysisStepsByIdManuallyMarkCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}/ManuallyMarkComplete`,
        method: "POST",
        body: queryArg.scanAnalysisStepManualCompletionRequest,
      }),
    }),
    postApiScanAnalysisSteps: build.mutation<
      PostApiScanAnalysisStepsApiResponse,
      PostApiScanAnalysisStepsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps`,
        method: "POST",
        body: queryArg.scanAnalysisStep,
      }),
    }),
    getApiScanAnalysisSteps: build.query<
      GetApiScanAnalysisStepsApiResponse,
      GetApiScanAnalysisStepsApiArg
    >({
      query: () => ({ url: `/api/ScanAnalysisSteps` }),
    }),
    putApiScanAnalysisStepsById: build.mutation<
      PutApiScanAnalysisStepsByIdApiResponse,
      PutApiScanAnalysisStepsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}`,
        method: "PUT",
        body: queryArg.scanAnalysisStep,
      }),
    }),
    deleteApiScanAnalysisStepsById: build.mutation<
      DeleteApiScanAnalysisStepsByIdApiResponse,
      DeleteApiScanAnalysisStepsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiScanAnalysisStepsById: build.query<
      GetApiScanAnalysisStepsByIdApiResponse,
      GetApiScanAnalysisStepsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalysisSteps/${queryArg.id}` }),
    }),
    getApiScanAnalysisViewModelsById: build.query<
      GetApiScanAnalysisViewModelsByIdApiResponse,
      GetApiScanAnalysisViewModelsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisViewModels/${queryArg.id}`,
      }),
    }),
    getApiScanModalitiesById: build.query<
      GetApiScanModalitiesByIdApiResponse,
      GetApiScanModalitiesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanModalities/${queryArg.id}` }),
    }),
    getApiScanModalities: build.query<
      GetApiScanModalitiesApiResponse,
      GetApiScanModalitiesApiArg
    >({
      query: () => ({ url: `/api/ScanModalities` }),
    }),
    postApiScanpackagesProcessRequest: build.mutation<
      PostApiScanpackagesProcessRequestApiResponse,
      PostApiScanpackagesProcessRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/scanpackages/process_request`,
        method: "POST",
        body: queryArg.scanPackageProcessRequest,
      }),
    }),
    postApiScanpackagesValidation: build.mutation<
      PostApiScanpackagesValidationApiResponse,
      PostApiScanpackagesValidationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/scanpackages/validation`,
        method: "POST",
        body: queryArg.scanPackageProcessRequest,
      }),
    }),
    postApiScanpackagesValidateManifestFile: build.mutation<
      PostApiScanpackagesValidateManifestFileApiResponse,
      PostApiScanpackagesValidateManifestFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/scanpackages/validate_manifest_file`,
        method: "POST",
        body: queryArg.scanPackageManifest,
      }),
    }),
    getApiScanProcessingOutputsTemporaryUriByRelativeFilePath: build.query<
      GetApiScanProcessingOutputsTemporaryUriByRelativeFilePathApiResponse,
      GetApiScanProcessingOutputsTemporaryUriByRelativeFilePathApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingOutputs/temporaryUriByRelativeFilePath`,
        params: {
          fileRelativePath: queryArg.fileRelativePath,
        },
      }),
    }),
    getApiScanProcessingOutputsJsonContentByRelativePath: build.query<
      GetApiScanProcessingOutputsJsonContentByRelativePathApiResponse,
      GetApiScanProcessingOutputsJsonContentByRelativePathApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingOutputs/JsonContentByRelativePath`,
        params: {
          fileRelativePath: queryArg.fileRelativePath,
        },
      }),
    }),
    getApiScanProcessingTaskArgumentsById: build.query<
      GetApiScanProcessingTaskArgumentsByIdApiResponse,
      GetApiScanProcessingTaskArgumentsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTaskArguments/${queryArg.id}`,
      }),
    }),
    getApiScanProcessingTaskArguments: build.query<
      GetApiScanProcessingTaskArgumentsApiResponse,
      GetApiScanProcessingTaskArgumentsApiArg
    >({
      query: () => ({ url: `/api/ScanProcessingTaskArguments` }),
    }),
    postApiScanProcessingTasksByIdRequestsStart: build.mutation<
      PostApiScanProcessingTasksByIdRequestsStartApiResponse,
      PostApiScanProcessingTasksByIdRequestsStartApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/Requests/Start`,
        method: "POST",
        body: queryArg.scanProcessingTaskStartRequest,
      }),
    }),
    getApiScanProcessingTasksByIdDto: build.query<
      GetApiScanProcessingTasksByIdDtoApiResponse,
      GetApiScanProcessingTasksByIdDtoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/Dto`,
      }),
    }),
    getApiScanProcessingTasksByIdStatuses: build.query<
      GetApiScanProcessingTasksByIdStatusesApiResponse,
      GetApiScanProcessingTasksByIdStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/statuses`,
      }),
    }),
    postApiScanProcessingTasksByIdSingleStepOutputArtifactFile: build.mutation<
      PostApiScanProcessingTasksByIdSingleStepOutputArtifactFileApiResponse,
      PostApiScanProcessingTasksByIdSingleStepOutputArtifactFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/SingleStepOutputArtifactFile`,
        method: "POST",
        body: queryArg.body,
        params: {
          outputSystemId: queryArg.outputSystemId,
          taskArgumentIndex: queryArg.taskArgumentIndex,
          reason: queryArg.reason,
        },
      }),
    }),
    postApiScanProcessingTasksByIdSingleStepOutputArtifactText: build.mutation<
      PostApiScanProcessingTasksByIdSingleStepOutputArtifactTextApiResponse,
      PostApiScanProcessingTasksByIdSingleStepOutputArtifactTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/SingleStepOutputArtifactText`,
        method: "POST",
        body: queryArg.body,
        params: {
          outputSystemId: queryArg.outputSystemId,
          taskArgumentIndex: queryArg.taskArgumentIndex,
          reason: queryArg.reason,
        },
      }),
    }),
    getApiScanProcessingTasksByIdSingleScanInputSourceUrl: build.query<
      GetApiScanProcessingTasksByIdSingleScanInputSourceUrlApiResponse,
      GetApiScanProcessingTasksByIdSingleScanInputSourceUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/SingleScanInputSourceUrl`,
        params: {
          analysisTaskArgumentKey: queryArg.analysisTaskArgumentKey,
        },
      }),
    }),
    getApiScanProcessingTasksByIdArtifactSideloadHistory: build.query<
      GetApiScanProcessingTasksByIdArtifactSideloadHistoryApiResponse,
      GetApiScanProcessingTasksByIdArtifactSideloadHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/ArtifactSideloadHistory`,
        params: {
          outputSystemId: queryArg.outputSystemId,
          taskArgumentIndex: queryArg.taskArgumentIndex,
        },
      }),
    }),
    postApiScanProcessingTasks: build.mutation<
      PostApiScanProcessingTasksApiResponse,
      PostApiScanProcessingTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks`,
        method: "POST",
        body: queryArg.scanProcessingTask,
      }),
    }),
    getApiScanProcessingTasks: build.query<
      GetApiScanProcessingTasksApiResponse,
      GetApiScanProcessingTasksApiArg
    >({
      query: () => ({ url: `/api/ScanProcessingTasks` }),
    }),
    putApiScanProcessingTasksById: build.mutation<
      PutApiScanProcessingTasksByIdApiResponse,
      PutApiScanProcessingTasksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}`,
        method: "PUT",
        body: queryArg.scanProcessingTask,
      }),
    }),
    deleteApiScanProcessingTasksById: build.mutation<
      DeleteApiScanProcessingTasksByIdApiResponse,
      DeleteApiScanProcessingTasksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiScanProcessingTasksById: build.query<
      GetApiScanProcessingTasksByIdApiResponse,
      GetApiScanProcessingTasksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanProcessingTasks/${queryArg.id}` }),
    }),
    getApiScanProcessingTaskStatusesById: build.query<
      GetApiScanProcessingTaskStatusesByIdApiResponse,
      GetApiScanProcessingTaskStatusesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTaskStatuses/${queryArg.id}`,
      }),
    }),
    getApiScanProcessingTaskStatuses: build.query<
      GetApiScanProcessingTaskStatusesApiResponse,
      GetApiScanProcessingTaskStatusesApiArg
    >({
      query: () => ({ url: `/api/ScanProcessingTaskStatuses` }),
    }),
    getApiScanProcessingTaskStatusTypesById: build.query<
      GetApiScanProcessingTaskStatusTypesByIdApiResponse,
      GetApiScanProcessingTaskStatusTypesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTaskStatusTypes/${queryArg.id}`,
      }),
    }),
    getApiScanProcessingTaskStatusTypes: build.query<
      GetApiScanProcessingTaskStatusTypesApiResponse,
      GetApiScanProcessingTaskStatusTypesApiArg
    >({
      query: () => ({ url: `/api/ScanProcessingTaskStatusTypes` }),
    }),
    getApiScansByIdExam: build.query<
      GetApiScansByIdExamApiResponse,
      GetApiScansByIdExamApiArg
    >({
      query: (queryArg) => ({ url: `/api/Scans/${queryArg.id}/Exam` }),
    }),
    postApiScans: build.mutation<PostApiScansApiResponse, PostApiScansApiArg>({
      query: (queryArg) => ({
        url: `/api/Scans`,
        method: "POST",
        body: queryArg.scan,
      }),
    }),
    getApiScans: build.query<GetApiScansApiResponse, GetApiScansApiArg>({
      query: () => ({ url: `/api/Scans` }),
    }),
    putApiScansById: build.mutation<
      PutApiScansByIdApiResponse,
      PutApiScansByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Scans/${queryArg.id}`,
        method: "PUT",
        body: queryArg.scan,
      }),
    }),
    deleteApiScansById: build.mutation<
      DeleteApiScansByIdApiResponse,
      DeleteApiScansByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Scans/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiScansById: build.query<
      GetApiScansByIdApiResponse,
      GetApiScansByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Scans/${queryArg.id}` }),
    }),
    getApiSexesById: build.query<
      GetApiSexesByIdApiResponse,
      GetApiSexesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Sexes/${queryArg.id}` }),
    }),
    getApiSexes: build.query<GetApiSexesApiResponse, GetApiSexesApiArg>({
      query: () => ({ url: `/api/Sexes` }),
    }),
    getApiSingleScanApplications: build.query<
      GetApiSingleScanApplicationsApiResponse,
      GetApiSingleScanApplicationsApiArg
    >({
      query: () => ({ url: `/api/singleScanApplications` }),
    }),
    getApiStudiesByIdExams: build.query<
      GetApiStudiesByIdExamsApiResponse,
      GetApiStudiesByIdExamsApiArg
    >({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}/Exams` }),
    }),
    postApiStudiesByIdPatientsAndPatientId: build.mutation<
      PostApiStudiesByIdPatientsAndPatientIdApiResponse,
      PostApiStudiesByIdPatientsAndPatientIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/patients/${queryArg.patientId}`,
        method: "POST",
      }),
    }),
    getApiStudiesByIdPatients: build.query<
      GetApiStudiesByIdPatientsApiResponse,
      GetApiStudiesByIdPatientsApiArg
    >({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}/patients` }),
    }),
    getApiStudiesByIdTimepoints: build.query<
      GetApiStudiesByIdTimepointsApiResponse,
      GetApiStudiesByIdTimepointsApiArg
    >({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}/timepoints` }),
    }),
    getApiStudiesByIdScanProcessingTaskStatuses: build.query<
      GetApiStudiesByIdScanProcessingTaskStatusesApiResponse,
      GetApiStudiesByIdScanProcessingTaskStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/scanProcessingTaskStatuses`,
      }),
    }),
    getApiStudiesByIdScanAnalysisViewModels: build.query<
      GetApiStudiesByIdScanAnalysisViewModelsApiResponse,
      GetApiStudiesByIdScanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/scanAnalysisViewModels`,
        params: {
          patientIdentifierSearchPattern:
            queryArg.patientIdentifierSearchPattern,
          compatibleInterscanApplicationFilter:
            queryArg.compatibleInterscanApplicationFilter,
          pageNumber: queryArg.pageNumber,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    getApiStudiesByIdScanAnalysisViewModelsByIds: build.query<
      GetApiStudiesByIdScanAnalysisViewModelsByIdsApiResponse,
      GetApiStudiesByIdScanAnalysisViewModelsByIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/scanAnalysisViewModelsByIds`,
        params: {
          scanAnalysisIds: queryArg.scanAnalysisIds,
        },
      }),
    }),
    getApiStudiesByIdInterscanAnalysisViewModels: build.query<
      GetApiStudiesByIdInterscanAnalysisViewModelsApiResponse,
      GetApiStudiesByIdInterscanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/interscanAnalysisViewModels`,
        params: {
          patientIdentifierSearchPattern:
            queryArg.patientIdentifierSearchPattern,
          compatibleInterscanApplicationFilter:
            queryArg.compatibleInterscanApplicationFilter,
          pageNumber: queryArg.pageNumber,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    getApiStudiesByIdCompoundInterscanAnalysisViewModels: build.query<
      GetApiStudiesByIdCompoundInterscanAnalysisViewModelsApiResponse,
      GetApiStudiesByIdCompoundInterscanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/compoundInterscanAnalysisViewModels`,
        params: {
          patientIdentifierSearchPattern:
            queryArg.patientIdentifierSearchPattern,
          pageNumber: queryArg.pageNumber,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    postApiStudies: build.mutation<
      PostApiStudiesApiResponse,
      PostApiStudiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies`,
        method: "POST",
        body: queryArg.study,
      }),
    }),
    getApiStudies: build.query<GetApiStudiesApiResponse, GetApiStudiesApiArg>({
      query: () => ({ url: `/api/Studies` }),
    }),
    putApiStudiesById: build.mutation<
      PutApiStudiesByIdApiResponse,
      PutApiStudiesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}`,
        method: "PUT",
        body: queryArg.study,
      }),
    }),
    deleteApiStudiesById: build.mutation<
      DeleteApiStudiesByIdApiResponse,
      DeleteApiStudiesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiStudiesById: build.query<
      GetApiStudiesByIdApiResponse,
      GetApiStudiesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}` }),
    }),
    getApiStudyStatusesById: build.query<
      GetApiStudyStatusesByIdApiResponse,
      GetApiStudyStatusesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/StudyStatuses/${queryArg.id}` }),
    }),
    getApiStudyStatuses: build.query<
      GetApiStudyStatusesApiResponse,
      GetApiStudyStatusesApiArg
    >({
      query: () => ({ url: `/api/StudyStatuses` }),
    }),
    postApiTimepoints: build.mutation<
      PostApiTimepointsApiResponse,
      PostApiTimepointsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Timepoints`,
        method: "POST",
        body: queryArg.timepoint,
      }),
    }),
    getApiTimepoints: build.query<
      GetApiTimepointsApiResponse,
      GetApiTimepointsApiArg
    >({
      query: () => ({ url: `/api/Timepoints` }),
    }),
    putApiTimepointsById: build.mutation<
      PutApiTimepointsByIdApiResponse,
      PutApiTimepointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Timepoints/${queryArg.id}`,
        method: "PUT",
        body: queryArg.timepoint,
      }),
    }),
    deleteApiTimepointsById: build.mutation<
      DeleteApiTimepointsByIdApiResponse,
      DeleteApiTimepointsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Timepoints/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiTimepointsById: build.query<
      GetApiTimepointsByIdApiResponse,
      GetApiTimepointsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Timepoints/${queryArg.id}` }),
    }),
    getApiTracersById: build.query<
      GetApiTracersByIdApiResponse,
      GetApiTracersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Tracers/${queryArg.id}` }),
    }),
    getApiTracers: build.query<GetApiTracersApiResponse, GetApiTracersApiArg>({
      query: () => ({ url: `/api/Tracers` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsApiResponse =
  /** status 200 OK */ ScanAnalysisViewModelRead[];
export type GetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsApiArg = {
  /** The ID of the Interscan Analysis to get Scan Analysis View models for */
  id: string;
};
export type PostApiCompoundInterscanAnalysesCreateAndInitializeApiResponse =
  /** status 200 OK */ CompoundInterscanAnalysis;
export type PostApiCompoundInterscanAnalysesCreateAndInitializeApiArg = {
  /** The CompoundInterscanAnalysisCreateAndInitializeRequest request object holding parameters for the request */
  compoundInterscanAnalysisCreateAndInitializeRequest: CompoundInterscanAnalysisCreateAndInitializeRequest;
};
export type PostApiCompoundInterscanAnalysesApiResponse =
  /** status 200 OK */ CompoundInterscanAnalysis;
export type PostApiCompoundInterscanAnalysesApiArg = {
  compoundInterscanAnalysis: CompoundInterscanAnalysis;
};
export type GetApiCompoundInterscanAnalysesApiResponse =
  /** status 200 OK */ CompoundInterscanAnalysis[];
export type GetApiCompoundInterscanAnalysesApiArg = void;
export type PutApiCompoundInterscanAnalysesByIdApiResponse = unknown;
export type PutApiCompoundInterscanAnalysesByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  compoundInterscanAnalysis: CompoundInterscanAnalysis;
};
export type DeleteApiCompoundInterscanAnalysesByIdApiResponse = unknown;
export type DeleteApiCompoundInterscanAnalysesByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiCompoundInterscanAnalysesByIdApiResponse =
  /** status 200 OK */ CompoundInterscanAnalysis;
export type GetApiCompoundInterscanAnalysesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiCompoundInterscanAnalysisViewModelsByIdApiResponse =
  /** status 200 OK */ CompoundInterscanAnalysisViewModelRead;
export type GetApiCompoundInterscanAnalysisViewModelsByIdApiArg = {
  /** The Id of the Interscan Analysis to get a View Model for */
  id: string;
};
export type GetApiCompoundInterscanApplicationsApiResponse =
  /** status 200 OK */ CompoundInterscanApplication[];
export type GetApiCompoundInterscanApplicationsApiArg = void;
export type GetApiConfigurationApiResponse = unknown;
export type GetApiConfigurationApiArg = void;
export type GetApiConfigurationBuildInfoApiResponse = unknown;
export type GetApiConfigurationBuildInfoApiArg = void;
export type PostApiExamsByIdScansApiResponse = /** status 200 OK */ Scan;
export type PostApiExamsByIdScansApiArg = {
  /** The ID of the Exam to add the Scan to */
  id: string;
  /** The new Scan */
  scan: Scan;
};
export type GetApiExamsByIdScansApiResponse = /** status 200 OK */ Scan[];
export type GetApiExamsByIdScansApiArg = {
  /** The ID of the Exam to get scans for */
  id: string;
  /** (optional) The ID of the ModalityLabel to filter by */
  modalityId?: string;
};
export type PostApiExamsApiResponse = /** status 200 OK */ Exam;
export type PostApiExamsApiArg = {
  exam: Exam;
};
export type GetApiExamsApiResponse = /** status 200 OK */ Exam[];
export type GetApiExamsApiArg = void;
export type PutApiExamsByIdApiResponse = unknown;
export type PutApiExamsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  exam: Exam;
};
export type DeleteApiExamsByIdApiResponse = unknown;
export type DeleteApiExamsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiExamsByIdApiResponse = /** status 200 OK */ Exam;
export type GetApiExamsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiIdsPatientsApiResponse = /** status 200 OK */ string[];
export type GetApiIdsPatientsApiArg = {
  /** The identifier of the Patients to get GUID Ids for */
  identifier?: string;
  /** The ID of the study to narrow results by */
  studyId?: string;
};
export type GetApiIdsStudyApiResponse = /** status 200 OK */ string;
export type GetApiIdsStudyApiArg = {
  /** The name of the Study to get the ID for */
  name?: string;
};
export type GetApiIdsTimepointsApiResponse = /** status 200 OK */ string[];
export type GetApiIdsTimepointsApiArg = {
  /** The name of the Timepoints to get GUID Ids for */
  name?: string;
  /** The ID of the study to narrow results by */
  studyId?: string;
};
export type PostApiInterscanAnalysesCreateAndInitializeUngroupedApiResponse =
  /** status 200 OK */ InterscanAnalysis;
export type PostApiInterscanAnalysesCreateAndInitializeUngroupedApiArg = {
  ungroupedInterscanAnalysisCreateAndInitializeRequest: UngroupedInterscanAnalysisCreateAndInitializeRequest;
};
export type PostApiInterscanAnalysesCreateAndInitializeGroupedApiResponse =
  /** status 200 OK */ InterscanAnalysis;
export type PostApiInterscanAnalysesCreateAndInitializeGroupedApiArg = {
  groupedInterscanAnalysisCreateAndInitializeRequest: GroupedInterscanAnalysisCreateAndInitializeRequest;
};
export type GetApiInterscanAnalysesByIdScanAnalysisViewModelsApiResponse =
  /** status 200 OK */ ScanAnalysisViewModelRead[];
export type GetApiInterscanAnalysesByIdScanAnalysisViewModelsApiArg = {
  /** The ID of the Interscan Analysis to get Scan Analysis View models for */
  id: string;
};
export type PostApiInterscanAnalysesApiResponse =
  /** status 200 OK */ InterscanAnalysis;
export type PostApiInterscanAnalysesApiArg = {
  interscanAnalysis: InterscanAnalysis;
};
export type GetApiInterscanAnalysesApiResponse =
  /** status 200 OK */ InterscanAnalysis[];
export type GetApiInterscanAnalysesApiArg = void;
export type PutApiInterscanAnalysesByIdApiResponse = unknown;
export type PutApiInterscanAnalysesByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  interscanAnalysis: InterscanAnalysis;
};
export type DeleteApiInterscanAnalysesByIdApiResponse = unknown;
export type DeleteApiInterscanAnalysesByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiInterscanAnalysesByIdApiResponse =
  /** status 200 OK */ InterscanAnalysis;
export type GetApiInterscanAnalysesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiInterscanAnalysisViewModelsByIdApiResponse =
  /** status 200 OK */ InterscanAnalysisViewModelRead;
export type GetApiInterscanAnalysisViewModelsByIdApiArg = {
  /** The Id of the Interscan Analysis to get a View Model for */
  id: string;
};
export type GetApiInterscanApplicationsApiResponse =
  /** status 200 OK */ InterscanApplication[];
export type GetApiInterscanApplicationsApiArg = void;
export type GetApiPatientsByIdExamsApiResponse = /** status 200 OK */ Exam[];
export type GetApiPatientsByIdExamsApiArg = {
  /** The ID of the Patient to get Exams for */
  id: string;
};
export type PostApiPatientsApiResponse = /** status 200 OK */ Patient;
export type PostApiPatientsApiArg = {
  patient: Patient;
};
export type GetApiPatientsApiResponse = /** status 200 OK */ Patient[];
export type GetApiPatientsApiArg = void;
export type PutApiPatientsByIdApiResponse = unknown;
export type PutApiPatientsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  patient: Patient;
};
export type DeleteApiPatientsByIdApiResponse = unknown;
export type DeleteApiPatientsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiPatientsByIdApiResponse = /** status 200 OK */ Patient;
export type GetApiPatientsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiPlatformRolesByIdApiResponse =
  /** status 200 OK */ PlatformRole;
export type GetApiPlatformRolesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiPlatformRolesApiResponse =
  /** status 200 OK */ PlatformRole[];
export type GetApiPlatformRolesApiArg = void;
export type GetApiPlatformUsersSelfApiResponse =
  /** status 200 OK */ PlatformUser;
export type GetApiPlatformUsersSelfApiArg = void;
export type GetApiPlatformUsersByIdRolesApiResponse =
  /** status 200 OK */ PlatformRole[];
export type GetApiPlatformUsersByIdRolesApiArg = {
  /** The ID of the Platform User to get roles for */
  id: string;
};
export type PostApiPlatformUsersByIdRolesApiResponse = unknown;
export type PostApiPlatformUsersByIdRolesApiArg = {
  /** The ID of the Platform User to assign the Platform Role */
  id: string;
  /** The ID of the Platform Role to add */
  body: string;
};
export type DeleteApiPlatformUsersByIdRolesAndRoleIdApiResponse = unknown;
export type DeleteApiPlatformUsersByIdRolesAndRoleIdApiArg = {
  /** The ID of the Platform User to remove the Platform Role assignment from */
  id: string;
  /** The ID of the Platform Role to remove */
  roleId: string;
};
export type PostApiPlatformUsersApiResponse = /** status 200 OK */ PlatformUser;
export type PostApiPlatformUsersApiArg = {
  platformUser: PlatformUser;
};
export type GetApiPlatformUsersApiResponse =
  /** status 200 OK */ PlatformUser[];
export type GetApiPlatformUsersApiArg = void;
export type PutApiPlatformUsersByIdApiResponse = unknown;
export type PutApiPlatformUsersByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  platformUser: PlatformUser;
};
export type DeleteApiPlatformUsersByIdApiResponse = unknown;
export type DeleteApiPlatformUsersByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiPlatformUsersByIdApiResponse =
  /** status 200 OK */ PlatformUser;
export type GetApiPlatformUsersByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type PostApiResponseAnalysisReportsGenerateApiResponse =
  /** status 200 OK */ ResponseAnalysisReport;
export type PostApiResponseAnalysisReportsGenerateApiArg = {
  /** A request object containing the ID of the interscan analysis to generate a report for */
  responseAnalysisReportCreationRequest: ResponseAnalysisReportCreationRequest;
};
export type GetApiResponseAnalysisReportsLightViewModelsApiResponse =
  /** status 200 OK */ ReportLightViewModel[];
export type GetApiResponseAnalysisReportsLightViewModelsApiArg = void;
export type PostApiResponseAnalysisReportsApiResponse =
  /** status 200 OK */ ResponseAnalysisReport;
export type PostApiResponseAnalysisReportsApiArg = {
  responseAnalysisReport: ResponseAnalysisReport;
};
export type GetApiResponseAnalysisReportsApiResponse =
  /** status 200 OK */ ResponseAnalysisReport[];
export type GetApiResponseAnalysisReportsApiArg = void;
export type PutApiResponseAnalysisReportsByIdApiResponse = unknown;
export type PutApiResponseAnalysisReportsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  responseAnalysisReport: ResponseAnalysisReport;
};
export type DeleteApiResponseAnalysisReportsByIdApiResponse = unknown;
export type DeleteApiResponseAnalysisReportsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiResponseAnalysisReportsByIdApiResponse =
  /** status 200 OK */ ResponseAnalysisReport;
export type GetApiResponseAnalysisReportsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanAnalysesByScansApiResponse =
  /** status 200 OK */ ScanAnalysis;
export type GetApiScanAnalysesByScansApiArg = {
  /** The ID of the Anatomical Scan */
  anatomicalScanId?: string;
  /** (optional) The ID of Functional Scan - nullable */
  functionalScanId?: string;
};
export type GetApiScanAnalysesByIdStepsApiResponse =
  /** status 200 OK */ ScanAnalysisStep[];
export type GetApiScanAnalysesByIdStepsApiArg = {
  /** The ID of the Scan Analysis to get steps for */
  id: string;
};
export type PostApiScanAnalysesApiResponse = /** status 200 OK */ ScanAnalysis;
export type PostApiScanAnalysesApiArg = {
  scanAnalysis: ScanAnalysis;
};
export type GetApiScanAnalysesApiResponse = /** status 200 OK */ ScanAnalysis[];
export type GetApiScanAnalysesApiArg = void;
export type PutApiScanAnalysesByIdApiResponse = unknown;
export type PutApiScanAnalysesByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  scanAnalysis: ScanAnalysis;
};
export type DeleteApiScanAnalysesByIdApiResponse = unknown;
export type DeleteApiScanAnalysesByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiScanAnalysesByIdApiResponse =
  /** status 200 OK */ ScanAnalysis;
export type GetApiScanAnalysesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type PostApiScanAnalysisStepsByIdTasksApiResponse =
  /** status 200 OK */ ScanProcessingTask;
export type PostApiScanAnalysisStepsByIdTasksApiArg = {
  /** The ID of the Job to add the Task to */
  id: string;
  /** The Scan Processing Task to add */
  scanProcessingTask: ScanProcessingTask;
};
export type GetApiScanAnalysisStepsByIdTasksApiResponse =
  /** status 200 OK */ ScanProcessingTask[];
export type GetApiScanAnalysisStepsByIdTasksApiArg = {
  id: string;
};
export type PostApiScanAnalysisStepsByIdTransitionApiResponse =
  /** status 200 OK */ ScanAnalysisStep;
export type PostApiScanAnalysisStepsByIdTransitionApiArg = {
  id: string;
  scanAnalysisStepTransitionRequest: ScanAnalysisStepTransitionRequest;
};
export type GetApiScanAnalysisStepsByIdArtifactUrlsApiResponse =
  /** status 200 OK */ ScanAnalysisStepArtifactViewModel[];
export type GetApiScanAnalysisStepsByIdArtifactUrlsApiArg = {
  /** The ID of the Scan Processing Step to get the output URLs from */
  id: string;
};
export type PostApiScanAnalysisStepsByIdManuallyMarkCompleteApiResponse =
  unknown;
export type PostApiScanAnalysisStepsByIdManuallyMarkCompleteApiArg = {
  id: string;
  scanAnalysisStepManualCompletionRequest: ScanAnalysisStepManualCompletionRequest;
};
export type PostApiScanAnalysisStepsApiResponse =
  /** status 200 OK */ ScanAnalysisStep;
export type PostApiScanAnalysisStepsApiArg = {
  scanAnalysisStep: ScanAnalysisStep;
};
export type GetApiScanAnalysisStepsApiResponse =
  /** status 200 OK */ ScanAnalysisStep[];
export type GetApiScanAnalysisStepsApiArg = void;
export type PutApiScanAnalysisStepsByIdApiResponse = unknown;
export type PutApiScanAnalysisStepsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  scanAnalysisStep: ScanAnalysisStep;
};
export type DeleteApiScanAnalysisStepsByIdApiResponse = unknown;
export type DeleteApiScanAnalysisStepsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiScanAnalysisStepsByIdApiResponse =
  /** status 200 OK */ ScanAnalysisStep;
export type GetApiScanAnalysisStepsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanAnalysisViewModelsByIdApiResponse =
  /** status 200 OK */ ScanAnalysisViewModelRead;
export type GetApiScanAnalysisViewModelsByIdApiArg = {
  /** The Id of the Scan Analysis to get a View Model for */
  id: string;
};
export type GetApiScanModalitiesByIdApiResponse =
  /** status 200 OK */ ScanModality;
export type GetApiScanModalitiesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanModalitiesApiResponse =
  /** status 200 OK */ ScanModality[];
export type GetApiScanModalitiesApiArg = void;
export type PostApiScanpackagesProcessRequestApiResponse = unknown;
export type PostApiScanpackagesProcessRequestApiArg = {
  /** The request, including the location of the Scan Package folder in a file share in a storage account associated with the
                current environment and an optional ID of a Study to associate the data with. If StudyID is absent, the ID/Name in the
                manifest will be used. */
  scanPackageProcessRequest: ScanPackageProcessRequest;
};
export type PostApiScanpackagesValidationApiResponse =
  /** status 200 OK */ ScanPackageValidationResultRead;
export type PostApiScanpackagesValidationApiArg = {
  /** The request, including the location of the Scan Package folder in a file share in a storage account associated with the
                current environment and an optional ID of a Study to associate the data with. If StudyID is absent, the ID/Name in the
                manifest will be used. */
  scanPackageProcessRequest: ScanPackageProcessRequest;
};
export type PostApiScanpackagesValidateManifestFileApiResponse =
  /** status 200 OK */ ScanPackageValidationResultRead;
export type PostApiScanpackagesValidateManifestFileApiArg = {
  /** The manifest file to validate */
  scanPackageManifest: ScanPackageManifest;
};
export type GetApiScanProcessingOutputsTemporaryUriByRelativeFilePathApiResponse =
  /** status 200 OK */ string;
export type GetApiScanProcessingOutputsTemporaryUriByRelativeFilePathApiArg = {
  fileRelativePath?: string;
};
export type GetApiScanProcessingOutputsJsonContentByRelativePathApiResponse =
  /** status 200 OK */ string;
export type GetApiScanProcessingOutputsJsonContentByRelativePathApiArg = {
  fileRelativePath?: string;
};
export type GetApiScanProcessingTaskArgumentsByIdApiResponse =
  /** status 200 OK */ ScanProcessingTaskArgument;
export type GetApiScanProcessingTaskArgumentsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanProcessingTaskArgumentsApiResponse =
  /** status 200 OK */ ScanProcessingTaskArgument[];
export type GetApiScanProcessingTaskArgumentsApiArg = void;
export type PostApiScanProcessingTasksByIdRequestsStartApiResponse = unknown;
export type PostApiScanProcessingTasksByIdRequestsStartApiArg = {
  id: string;
  /** The Start Request */
  scanProcessingTaskStartRequest: ScanProcessingTaskStartRequest;
};
export type GetApiScanProcessingTasksByIdDtoApiResponse =
  /** status 200 OK */ string;
export type GetApiScanProcessingTasksByIdDtoApiArg = {
  /** The ID of the Scan Processing Task to get the DTO */
  id: string;
};
export type GetApiScanProcessingTasksByIdStatusesApiResponse =
  /** status 200 OK */ ScanProcessingTaskStatus[];
export type GetApiScanProcessingTasksByIdStatusesApiArg = {
  /** The ID of the Scan Processing Task to get Statuses for */
  id: string;
};
export type PostApiScanProcessingTasksByIdSingleStepOutputArtifactFileApiResponse =
  /** status 200 OK */ ScanAnalysisStepArtifactViewModel;
export type PostApiScanProcessingTasksByIdSingleStepOutputArtifactFileApiArg = {
  /** The ID of the Scan Processing Task to write the output to */
  id: string;
  /** the System ID of the task Output */
  outputSystemId?: string;
  /** The index of this artifact in the case of multi-output arguments in the SPAT. */
  taskArgumentIndex?: number;
  /** Optional - A string describing the reason this file is being written this way */
  reason?: string;
  body: {
    formFile?: Blob;
  };
};
export type PostApiScanProcessingTasksByIdSingleStepOutputArtifactTextApiResponse =
  /** status 200 OK */ ScanAnalysisStepArtifactViewModel;
export type PostApiScanProcessingTasksByIdSingleStepOutputArtifactTextApiArg = {
  /** The ID of the Scan Processing Task to write the output to */
  id: string;
  /** the System ID of the task Output */
  outputSystemId?: string;
  /** The index of this artifact in the case of multi-output arguments in the SPAT. */
  taskArgumentIndex?: number;
  /** Optional - A string describing the reason this file is being written this way */
  reason?: string;
  body: string;
};
export type GetApiScanProcessingTasksByIdSingleScanInputSourceUrlApiResponse =
  /** status 200 OK */ ScanAnalysisStepArtifactViewModel;
export type GetApiScanProcessingTasksByIdSingleScanInputSourceUrlApiArg = {
  /** The ID of the Scan Processing Step to get the input source URL from */
  id: string;
  /** The index of this artifact in the case of multi-output arguments in the SPAT. */
  analysisTaskArgumentKey?: string;
};
export type GetApiScanProcessingTasksByIdArtifactSideloadHistoryApiResponse =
  /** status 200 OK */ ArtifactSideLoadHistoryViewModel[];
export type GetApiScanProcessingTasksByIdArtifactSideloadHistoryApiArg = {
  /** The unique identifier of the scan processing task. */
  id: string;
  /** The SystemID identifying the specific output. */
  outputSystemId?: string;
  /** The index of the task argument. */
  taskArgumentIndex?: number;
};
export type PostApiScanProcessingTasksApiResponse =
  /** status 200 OK */ ScanProcessingTask;
export type PostApiScanProcessingTasksApiArg = {
  scanProcessingTask: ScanProcessingTask;
};
export type GetApiScanProcessingTasksApiResponse =
  /** status 200 OK */ ScanProcessingTask[];
export type GetApiScanProcessingTasksApiArg = void;
export type PutApiScanProcessingTasksByIdApiResponse = unknown;
export type PutApiScanProcessingTasksByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  scanProcessingTask: ScanProcessingTask;
};
export type DeleteApiScanProcessingTasksByIdApiResponse = unknown;
export type DeleteApiScanProcessingTasksByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiScanProcessingTasksByIdApiResponse =
  /** status 200 OK */ ScanProcessingTask;
export type GetApiScanProcessingTasksByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanProcessingTaskStatusesByIdApiResponse =
  /** status 200 OK */ ScanProcessingTaskStatus;
export type GetApiScanProcessingTaskStatusesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanProcessingTaskStatusesApiResponse =
  /** status 200 OK */ ScanProcessingTaskStatus[];
export type GetApiScanProcessingTaskStatusesApiArg = void;
export type GetApiScanProcessingTaskStatusTypesByIdApiResponse =
  /** status 200 OK */ ScanProcessingTaskStatusType;
export type GetApiScanProcessingTaskStatusTypesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanProcessingTaskStatusTypesApiResponse =
  /** status 200 OK */ ScanProcessingTaskStatusType[];
export type GetApiScanProcessingTaskStatusTypesApiArg = void;
export type GetApiScansByIdExamApiResponse = /** status 200 OK */ Exam;
export type GetApiScansByIdExamApiArg = {
  /** The ID of the Scan to get the Exam for */
  id: string;
};
export type PostApiScansApiResponse = /** status 200 OK */ Scan;
export type PostApiScansApiArg = {
  scan: Scan;
};
export type GetApiScansApiResponse = /** status 200 OK */ Scan[];
export type GetApiScansApiArg = void;
export type PutApiScansByIdApiResponse = unknown;
export type PutApiScansByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  scan: Scan;
};
export type DeleteApiScansByIdApiResponse = unknown;
export type DeleteApiScansByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiScansByIdApiResponse = /** status 200 OK */ Scan;
export type GetApiScansByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiSexesByIdApiResponse = /** status 200 OK */ Sex;
export type GetApiSexesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiSexesApiResponse = /** status 200 OK */ Sex[];
export type GetApiSexesApiArg = void;
export type GetApiSingleScanApplicationsApiResponse =
  /** status 200 OK */ SingleScanApplication[];
export type GetApiSingleScanApplicationsApiArg = void;
export type GetApiStudiesByIdExamsApiResponse = /** status 200 OK */ Exam[];
export type GetApiStudiesByIdExamsApiArg = {
  /** The ID of the Study for which to retrieve Exams */
  id: string;
};
export type PostApiStudiesByIdPatientsAndPatientIdApiResponse = unknown;
export type PostApiStudiesByIdPatientsAndPatientIdApiArg = {
  /** The ID of the Study to associate the Patient with */
  id: string;
  /** The ID of the Patient to associate with the Study */
  patientId: string;
};
export type GetApiStudiesByIdPatientsApiResponse =
  /** status 200 OK */ Patient[];
export type GetApiStudiesByIdPatientsApiArg = {
  /** The ID of the Study to get Patients for */
  id: string;
};
export type GetApiStudiesByIdTimepointsApiResponse =
  /** status 200 OK */ Timepoint[];
export type GetApiStudiesByIdTimepointsApiArg = {
  /** The ID of the Study to get Timepoints for */
  id: string;
};
export type GetApiStudiesByIdScanProcessingTaskStatusesApiResponse =
  /** status 200 OK */ ScanProcessingTaskStatus[];
export type GetApiStudiesByIdScanProcessingTaskStatusesApiArg = {
  /** The ID of the Study to get all Scan Processing Task Statuses for */
  id: string;
};
export type GetApiStudiesByIdScanAnalysisViewModelsApiResponse =
  /** status 200 OK */ ScanAnalysisViewModelPagedResultRead;
export type GetApiStudiesByIdScanAnalysisViewModelsApiArg = {
  /** The ID of the Study to get Scan Analysis View Models for */
  id: string;
  /** If provided, results will be filtered to Scan Analyses with Patients whose Identifiers contain this string. Default is null. */
  patientIdentifierSearchPattern?: string;
  /** If provided, results will be filtered to only those Scan Analyses that are compatible as inputs to the Interscan Application with this SystemId */
  compatibleInterscanApplicationFilter?: string;
  /** For pagination support. If provided, results will be skipped to this page. Defaults to null. */
  pageNumber?: number;
  /** For pagination support. The number of records per page to return. Defaults to null. */
  pageSize?: number;
};
export type GetApiStudiesByIdScanAnalysisViewModelsByIdsApiResponse =
  /** status 200 OK */ ScanAnalysisViewModelRead[];
export type GetApiStudiesByIdScanAnalysisViewModelsByIdsApiArg = {
  /** The ID of the Study to get Scan Analysis View Models for */
  id: string;
  /** If provided, results will be filtered to Scan Analyses with Patients whose Identifiers contain this string. Default is null. */
  scanAnalysisIds?: string;
};
export type GetApiStudiesByIdInterscanAnalysisViewModelsApiResponse =
  /** status 200 OK */ InterscanAnalysisViewModelPagedResultRead;
export type GetApiStudiesByIdInterscanAnalysisViewModelsApiArg = {
  /** The ID of the Study to get Interscan Analysis View Models for */
  id: string;
  /** If provided, results will be filtered to Interscan Analyses with Patients whose Identifiers contain this string. Default is null. */
  patientIdentifierSearchPattern?: string;
  /** If provided, results will be filtered to only those Scan Analyses that are compatible as inputs to the Interscan Application with this SystemId */
  compatibleInterscanApplicationFilter?: string;
  /** For pagination support. If provided, results will be skipped to this page. Defaults to null. */
  pageNumber?: number;
  /** For pagination support. The number of records per page to return. Defaults to null. */
  pageSize?: number;
};
export type GetApiStudiesByIdCompoundInterscanAnalysisViewModelsApiResponse =
  /** status 200 OK */ CompoundInterscanAnalysisViewModelPagedResultRead;
export type GetApiStudiesByIdCompoundInterscanAnalysisViewModelsApiArg = {
  /** The ID of the Study to get Compound Interscan Analysis View Models for */
  id: string;
  /** If provided, results will be filtered to Compound Interscan Analyses with Patients whose Identifiers contain this string. Default is null. */
  patientIdentifierSearchPattern?: string;
  /** For pagination support. If provided, results will be skipped to this page. Defaults to null. */
  pageNumber?: number;
  /** For pagination support. The number of records per page to return. Defaults to null. */
  pageSize?: number;
};
export type PostApiStudiesApiResponse = /** status 200 OK */ Study;
export type PostApiStudiesApiArg = {
  study: Study;
};
export type GetApiStudiesApiResponse = /** status 200 OK */ Study[];
export type GetApiStudiesApiArg = void;
export type PutApiStudiesByIdApiResponse = unknown;
export type PutApiStudiesByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  study: Study;
};
export type DeleteApiStudiesByIdApiResponse = unknown;
export type DeleteApiStudiesByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiStudiesByIdApiResponse = /** status 200 OK */ Study;
export type GetApiStudiesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiStudyStatusesByIdApiResponse =
  /** status 200 OK */ StudyStatus;
export type GetApiStudyStatusesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiStudyStatusesApiResponse = /** status 200 OK */ StudyStatus[];
export type GetApiStudyStatusesApiArg = void;
export type PostApiTimepointsApiResponse = /** status 200 OK */ Timepoint;
export type PostApiTimepointsApiArg = {
  timepoint: Timepoint;
};
export type GetApiTimepointsApiResponse = /** status 200 OK */ Timepoint[];
export type GetApiTimepointsApiArg = void;
export type PutApiTimepointsByIdApiResponse = unknown;
export type PutApiTimepointsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  timepoint: Timepoint;
};
export type DeleteApiTimepointsByIdApiResponse = unknown;
export type DeleteApiTimepointsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiTimepointsByIdApiResponse = /** status 200 OK */ Timepoint;
export type GetApiTimepointsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiTracersByIdApiResponse = /** status 200 OK */ Tracer;
export type GetApiTracersByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiTracersApiResponse = /** status 200 OK */ Tracer[];
export type GetApiTracersApiArg = void;
export type AnalysisTypes =
  | "ScanAnalysis"
  | "InterscanAnalysis"
  | "CompoundInterscanAnalysis";
export type ScanAnalysis = {
  id?: string;
  type?: AnalysisTypes;
  /** The ID of the Single Scan Application used to process this Scan Analysis. This
    is a string ID used across the whole system stack. */
  applicationSystemId?: string | null;
  /** Scan ID of the anatomical scan (e.g. CT, MRI) */
  anatomicalScanId?: string;
  /** The ID of the Exam this Scan Analysis is associated with.
    This field is optional for backwards compatibility */
  examId?: string | null;
  /** Scan ID of the functional scan (e.g. PET) */
  functionalScanId?: string | null;
  /** The ID of the user who initiated the Scan Analysis */
  initiatedBy?: string;
  /** The time and date the Scan Analysis was initiated (UTC) */
  initiatedDate?: string | null;
  /** The URL (within the Azure File Share) to the bundle of VTK Meshes for display in the report */
  meshBundleUrl?: string | null;
};
export type ApplicationOutputTemplate = {
  description?: string | null;
  outputPath?: string | null;
  analysisTaskArgumentKey?: string | null;
  systemId?: string | null;
};
export type ApplicationExtraArgumentTemplate = {
  argumentValue?: string | null;
  analysisTaskArgumentKey?: string | null;
};
export type ApplicationInputTemplate = {
  analysisTaskArgumentKey?: string | null;
  sources?: ApplicationOutputTemplate[] | null;
  source?: ApplicationOutputTemplate;
};
export type SingleScanApplicationTaskTemplate = {
  systemId?: string | null;
  description?: string | null;
  queueName?: string | null;
  outputs?: ApplicationOutputTemplate[] | null;
  extraArguments?: ApplicationExtraArgumentTemplate[] | null;
  inputs?: ApplicationInputTemplate[] | null;
};
export type SingleScanApplicationTaskTemplateApplicationStepTemplate = {
  systemId?: string | null;
  displayName?: string | null;
  description?: string | null;
  automated?: boolean;
  stub?: boolean;
  pauseAfter?: boolean;
  tasks?: SingleScanApplicationTaskTemplate[] | null;
};
export type ScanApplicationAttribute = {
  systemId?: string | null;
  value?: string | null;
};
export type SingleScanApplication = {
  schemaVersion?: number;
  applicationVersion?: number;
  deprecated?: boolean;
  disabled?: boolean;
  displaySortIndex?: number | null;
  buildCommitSHA?: string | null;
  buildDateTime?: string | null;
  buildRunNumber?: string | null;
  systemId?: string | null;
  displayName?: string | null;
  relativeScanProcessingPath?: string | null;
  baseScanProcessingPath?: string | null;
  baseAIModelsPath?: string | null;
  description?: string | null;
  steps?: SingleScanApplicationTaskTemplateApplicationStepTemplate[] | null;
  attributes?: ScanApplicationAttribute[] | null;
  baseSourceDataPath?: string | null;
};
export type Patient = {
  id?: string;
  /** Unique identifier for the patient within the EMR system or within the scope of a clinical study */
  patientIdentifier?: string | null;
  /** The ID of the Sex associated with the Patient */
  sexId?: string;
  /** Weight of the patient as received from the DICOM. Normally in KG */
  weight?: number | null;
  /** The time and date the Patient record was created (UTC) */
  createdDate?: string;
  /** The time and date the Patient record was last updated (UTC) */
  updatedDate?: string;
};
export type Exam = {
  id?: string;
  /** The ID of the Patient examined */
  patientId?: string;
  /** The ID of the Study the Exam is associated with */
  studyId?: string;
  /** The ID of the Timepoint the Exam is associated with */
  timepointId?: string;
  /** The time and date when the Exam took place (UTC) */
  examDate?: string | null;
  /** The time and date the Exam record was created (UTC) */
  createdDate?: string;
  /** The time and date the Exam record was last updated (UTC) */
  updatedDate?: string;
};
export type Timepoint = {
  id?: string;
  /** The Study of which this timepoint is a part */
  studyId?: string;
  /** The name/label of the timepoint */
  timepointName?: string | null;
  /** Numeric sequence indicating the order of timepoints in the protocol */
  sequence?: number;
  /** The time and date the Timepoint record was created (UTC) */
  createdDate?: string;
  /** The time and date the Timepoint record was last updated (UTC) */
  updatedDate?: string;
};
export type Study = {
  id?: string;
  /** A display name for the Study */
  studyName?: string | null;
  /** The ID of the user that created the Study record */
  createdBy?: string;
  /** Name of the physician or research lead for the study */
  principalInvestigator?: string | null;
  /** The time and date the Study was started (UTC) */
  startDate?: string | null;
  /** The ID of the current Study Status assigned to the Study */
  statusId?: string | null;
  /** The time and date the Study record was created (UTC) */
  createdDate?: string;
  /** The time and date the Study record was last updated (UTC) */
  updatedDate?: string;
};
export type ScanAnalysisStep = {
  id?: string;
  /** The ID of the Step within the Single Scan Application used to process this Scan Analysis
    associated with this ScanAnalysisStep.
    This is a string ID used across the whole system stack. */
  applicationStepSystemId?: string | null;
  /** ID of the Analysis record */
  analysisId?: string;
  /** The ID of the user who initiated the Scan Analysis Step */
  initiatedBy?: string | null;
  /** The time and date the Scan Analysis Step was initiated (UTC) */
  initiatedDate?: string | null;
  /** True/false indicating whether or not the step has been approved */
  approved?: boolean | null;
  /** The ID of the user who approved the Scan Analysis Step, if any */
  approvedBy?: string | null;
  /** True/false indicating whether or not a step was rejected */
  rejected?: boolean | null;
  /** The ID of the user who rejected the Scan Analysis Step, if any */
  rejectedBy?: string | null;
  /** Reason for rejection, set by user or system */
  rejectedReason?: string | null;
  /** True/false indicating the step is complete */
  complete?: boolean | null;
};
export type ScanProcessingTask = {
  id?: string;
  /** The ID of the Task within the Single Scan Application used to process this Scan Analysis
    that is associated with this Scan Processing Task.
    This is a string ID used across the whole system stack. */
  applicationTaskSystemId?: string | null;
  /** The name of the Message Queue this task is requested on */
  queueName?: string | null;
  /** The Scan Analysis Step this Task is associated with */
  scanAnalysisStepId?: string;
};
export type ScanProcessingTaskStatusKind =
  | "UPDATE"
  | "WARNING"
  | "ALERT"
  | "ERROR";
export type ScanProcessingTaskStatus = {
  id?: string;
  /** The ID of the Scan Processing Task this Status is associated with */
  scanProcessingTaskId?: string;
  /** The ID of the type of status - e.g. processing, waiting, complete */
  scanProcessingTaskStatusTypeId?: string;
  kind?: ScanProcessingTaskStatusKind;
  /** The error associated with this status, if any */
  error?: string | null;
  /** The result associated with this status, if any */
  result?: string | null;
  /** A numerical representation of the progress of the task, if any */
  percentComplete?: number | null;
  /** The Date and Time this status was recorded */
  createdDate?: string;
  /** The ID of the user that created this status. Nullable, since sometimes the status
    will be added by the Agent rather than a User. */
  createdBy?: string | null;
};
export type ScanProcessingTaskStatusType = {
  id?: string;
  /** The display label for this status type */
  displayLabel?: string | null;
  /** This string value is used for mapping and should not be changed */
  constantStringValue?: string | null;
};
export type ScanProcessingTaskStatusViewModel = {
  scanProcessingTaskStatus?: ScanProcessingTaskStatus;
  scanProcessingTaskStatusType?: ScanProcessingTaskStatusType;
};
export type ScanProcessingTaskViewModel = {
  scanProcessingTask?: ScanProcessingTask;
  /** A list of View Models representing the statuses for this Scan Analysis Task, ordered by time (descending) */
  orderedScanProcessingTaskStatuses?:
    | ScanProcessingTaskStatusViewModel[]
    | null;
  mostRecentStatus?: ScanProcessingTaskStatusViewModel;
};
export type ScanProcessingTaskViewModelRead = {
  scanProcessingTask?: ScanProcessingTask;
  /** A list of View Models representing the statuses for this Scan Analysis Task, ordered by time (descending) */
  orderedScanProcessingTaskStatuses?:
    | ScanProcessingTaskStatusViewModel[]
    | null;
  /** A boolean indicating whether this Task's processing is in progress */
  isInProgress?: boolean;
  mostRecentStatus?: ScanProcessingTaskStatusViewModel;
  /** What percent of the work has been done for this task */
  percentComplete?: number;
  /** A boolean indicating whether this Task's processing is complete */
  isComplete?: boolean;
  /** A boolean indicating whether this Task is waiting to be processed */
  isWaiting?: boolean;
  /** A boolean indicating whether this Task has any Errors */
  hasErrors?: boolean;
};
export type ScanAnalysisStepViewModel = {
  scanAnalysisStep?: ScanAnalysisStep;
  /** A collection of View Models representing the Scan Processing Tasks comprising this Step */
  tasks?: ScanProcessingTaskViewModel[] | null;
  /** A boolean indicating whether this is an Automated Step */
  isAutomated?: boolean;
  /** A sequence number representing where in the Application sequence this Step falls */
  stepSequence?: number;
};
export type ScanAnalysisStepViewModelRead = {
  scanAnalysisStep?: ScanAnalysisStep;
  /** A collection of View Models representing the Scan Processing Tasks comprising this Step */
  tasks?: ScanProcessingTaskViewModelRead[] | null;
  /** A boolean indicating whether this Step's processing is in progress */
  isInProgress?: boolean;
  /** A boolean indicating whether this Step's processing is in Complete */
  isComplete?: boolean;
  /** A boolean indicating whether this Step is waiting to be processed */
  isWaiting?: boolean;
  /** A boolean indicating whether this Step has Errors */
  hasErrors?: boolean;
  /** A boolean indicating whether this is an Automated Step */
  isAutomated?: boolean;
  /** The time of the most recent Status update of any of this Step's Tasks */
  timeOfLastStatusUpdate?: string | null;
  /** The average of the current percent complete of all of this Step's Tasks */
  averagePercentComplete?: number;
  /** A sequence number representing where in the Application sequence this Step falls */
  stepSequence?: number;
};
export type Scan = {
  id?: string;
  /** The label applied to the scan. This is extracted from DICOM or specified by the system */
  scanLabel?: string | null;
  /** The Series number/code extracted from DICOM, representing reconstruction */
  scanSeries?: string | null;
  /** ModalityLabel of the scan (e.g. CT, PET, MRI) */
  scanModalityId?: string;
  /** The ID of the Patient who was the subject of the scan */
  patientId?: string;
  /** File location of the DICOM root folder for this scan */
  rawDataLocation?: string | null;
  /** The total Bone Volume - applies to CT scans only */
  boneVolume?: number | null;
  /** The time and date the Scan record was created (UTC) */
  createdDate?: string;
  /** The time and date the Scan record was last update (UTC) */
  updatedDate?: string;
};
export type ScanAnalysisViewModel = {
  scanAnalysis?: ScanAnalysis;
  singleScanApplication?: SingleScanApplication;
  patient?: Patient;
  exam?: Exam;
  timepoint?: Timepoint;
  study?: Study;
  /** An ordered list of View Models for the Scan Processing Steps created in the processing of this Scan Analysis */
  orderedScanAnalysisSteps?: ScanAnalysisStepViewModel[] | null;
  functionalScan?: Scan;
  anatomicalScan?: Scan;
};
export type ScanAnalysisViewModelRead = {
  scanAnalysis?: ScanAnalysis;
  singleScanApplication?: SingleScanApplication;
  patient?: Patient;
  exam?: Exam;
  timepoint?: Timepoint;
  study?: Study;
  /** An ordered list of View Models for the Scan Processing Steps created in the processing of this Scan Analysis */
  orderedScanAnalysisSteps?: ScanAnalysisStepViewModelRead[] | null;
  functionalScan?: Scan;
  anatomicalScan?: Scan;
};
export type CompoundInterscanAnalysis = {
  id?: string;
  type?: AnalysisTypes;
  /** The ID of the Interscan Application used to process this Interscan Analysis. This
    is a string ID used across the whole system stack. */
  applicationSystemId?: string | null;
  /** The ID of the user who initiated the Interscan Analysis */
  initiatedBy?: string;
  /** The time and date the Interscan Analysis was initiated (UTC) */
  initiatedDate?: string | null;
};
export type CompoundInterscanAnalysisCreateAndInitializeRequest = {
  /** the System ID of the Interscan Application that will be used to process the Secondary Interscan Analysis */
  compoundInterscanApplicationSystemId?: string | null;
  /** The IDs of the primary Interscan Analyses to use as inputs */
  inputInterscanAnalysisIds?: string[] | null;
};
export type InterscanReferenceLocalInputSource = {
  inputInterscanAnalysisIndex?: number;
  sourceOutputSystemId?: string | null;
};
export type InterscanReferenceLocalInput = {
  analysisTaskArgumentKey?: string | null;
  sources?: InterscanReferenceLocalInputSource[] | null;
};
export type InterscanReferenceSingleScanInputSource = {
  inputScanAnalysisIndex?: number;
  inputScanAnalysisGroupIndex?: number | null;
  sourceOutputSystemId?: string | null;
  inputInterscanAnalysisIndex?: number;
};
export type InterscanReferenceSingleScanInput = {
  analysisTaskArgumentKey?: string | null;
  sources?: InterscanReferenceSingleScanInputSource[] | null;
};
export type CompoundInterscanApplicationTaskTemplate = {
  systemId?: string | null;
  description?: string | null;
  queueName?: string | null;
  outputs?: ApplicationOutputTemplate[] | null;
  extraArguments?: ApplicationExtraArgumentTemplate[] | null;
  localInputs?: ApplicationInputTemplate[] | null;
  /** Input values referring to local outputs from one of the source Interscan Analyses */
  interscanReferenceLocalInputs?: InterscanReferenceLocalInput[] | null;
  /** Input values referring to outputs from a Single Scan referred to by one of the source Interscan Analyses */
  interscanReferenceSingleScanInputs?:
    | InterscanReferenceSingleScanInput[]
    | null;
};
export type CompoundInterscanApplicationTaskTemplateApplicationStepTemplate = {
  systemId?: string | null;
  displayName?: string | null;
  description?: string | null;
  automated?: boolean;
  stub?: boolean;
  pauseAfter?: boolean;
  tasks?: CompoundInterscanApplicationTaskTemplate[] | null;
};
export type CompoundInterscanApplication = {
  schemaVersion?: number;
  applicationVersion?: number;
  deprecated?: boolean;
  disabled?: boolean;
  displaySortIndex?: number | null;
  buildCommitSHA?: string | null;
  buildDateTime?: string | null;
  buildRunNumber?: string | null;
  systemId?: string | null;
  displayName?: string | null;
  relativeScanProcessingPath?: string | null;
  baseScanProcessingPath?: string | null;
  baseAIModelsPath?: string | null;
  description?: string | null;
  steps?:
    | CompoundInterscanApplicationTaskTemplateApplicationStepTemplate[]
    | null;
  attributes?: ScanApplicationAttribute[] | null;
  numberOfInputInterscanAnalyses?: number;
  validInterscanApplicationInputs?: string[] | null;
};
export type InterscanAnalysis = {
  id?: string;
  type?: AnalysisTypes;
  /** The ID of the Interscan Application used to process this Interscan Analysis. This
    is a string ID used across the whole system stack. */
  applicationSystemId?: string | null;
  /** The ID of the user who initiated the Interscan Analysis */
  initiatedBy?: string;
  /** The time and date the Interscan Analysis was initiated (UTC) */
  initiatedDate?: string | null;
};
export type SingleScanReferenceInputSource = {
  inputScanAnalysisIndex?: number;
  inputScanAnalysisGroupIndex?: number | null;
  sourceOutputSystemId?: string | null;
};
export type SingleScanReferenceInput = {
  sources?: SingleScanReferenceInputSource[] | null;
  source?: SingleScanReferenceInputSource;
  analysisTaskArgumentKey?: string | null;
};
export type InterscanApplicationTaskTemplate = {
  systemId?: string | null;
  description?: string | null;
  queueName?: string | null;
  outputs?: ApplicationOutputTemplate[] | null;
  extraArguments?: ApplicationExtraArgumentTemplate[] | null;
  singleScanReferenceInputs?: SingleScanReferenceInput[] | null;
  localInputs?: ApplicationInputTemplate[] | null;
};
export type InterscanApplicationTaskTemplateApplicationStepTemplate = {
  systemId?: string | null;
  displayName?: string | null;
  description?: string | null;
  automated?: boolean;
  stub?: boolean;
  pauseAfter?: boolean;
  tasks?: InterscanApplicationTaskTemplate[] | null;
};
export type InterscanApplication = {
  schemaVersion?: number;
  applicationVersion?: number;
  deprecated?: boolean;
  disabled?: boolean;
  displaySortIndex?: number | null;
  buildCommitSHA?: string | null;
  buildDateTime?: string | null;
  buildRunNumber?: string | null;
  systemId?: string | null;
  displayName?: string | null;
  relativeScanProcessingPath?: string | null;
  baseScanProcessingPath?: string | null;
  baseAIModelsPath?: string | null;
  description?: string | null;
  steps?: InterscanApplicationTaskTemplateApplicationStepTemplate[] | null;
  attributes?: ScanApplicationAttribute[] | null;
  numberOfInputSingleScanAnalyses?: number;
  validSingleScanApplicationInputs?: string[] | null;
  validInterscanApplicationInputs?: string[] | null;
  singleScanGroupSizes?: number[] | null;
  singleScanGroupDisplayNames?: string[] | null;
};
export type InterscanAnalysisViewModel = {
  interscanAnalysis?: InterscanAnalysis;
  interscanAnalysisApplication?: InterscanApplication;
  /** The groups of component source Singe Scan Analyses compared in the Interscan Analysis */
  sourceScanAnalyses?: ScanAnalysis[][] | null;
  /** View models of the groups of component source Singe Scan Analyses compared in the Interscan Analysis */
  sourceScanAnalysisVMs?: ScanAnalysisViewModel[][] | null;
  /** An ordered list of View Models for the Scan Processing Steps created in the processing of this Scan Analysis */
  orderedScanAnalysisSteps?: ScanAnalysisStepViewModel[] | null;
  study?: Study;
};
export type InterscanAnalysisViewModelRead = {
  interscanAnalysis?: InterscanAnalysis;
  interscanAnalysisApplication?: InterscanApplication;
  /** The groups of component source Singe Scan Analyses compared in the Interscan Analysis */
  sourceScanAnalyses?: ScanAnalysis[][] | null;
  /** View models of the groups of component source Singe Scan Analyses compared in the Interscan Analysis */
  sourceScanAnalysisVMs?: ScanAnalysisViewModelRead[][] | null;
  /** An ordered list of View Models for the Scan Processing Steps created in the processing of this Scan Analysis */
  orderedScanAnalysisSteps?: ScanAnalysisStepViewModelRead[] | null;
  study?: Study;
};
export type CompoundInterscanAnalysisViewModel = {
  compoundInterscanAnalysis?: CompoundInterscanAnalysis;
  compoundInterscanAnalysisApplication?: CompoundInterscanApplication;
  /** The groups of component source Singe Scan Analyses compared in the Interscan Analysis */
  sourceInterscanScanAnalysisVMs?: InterscanAnalysisViewModel[] | null;
  /** An ordered list of View Models for the Scan Processing Steps created in the processing of this Scan Analysis */
  orderedScanAnalysisSteps?: ScanAnalysisStepViewModel[] | null;
  study?: Study;
};
export type CompoundInterscanAnalysisViewModelRead = {
  compoundInterscanAnalysis?: CompoundInterscanAnalysis;
  compoundInterscanAnalysisApplication?: CompoundInterscanApplication;
  /** The groups of component source Singe Scan Analyses compared in the Interscan Analysis */
  sourceInterscanScanAnalysisVMs?: InterscanAnalysisViewModelRead[] | null;
  /** An ordered list of View Models for the Scan Processing Steps created in the processing of this Scan Analysis */
  orderedScanAnalysisSteps?: ScanAnalysisStepViewModelRead[] | null;
  study?: Study;
};
export type UngroupedInterscanAnalysisCreateAndInitializeRequest = {
  /** An ordered list of IDs for the Single Scan Analyses that will be used for the Interscan Analyses */
  singleScanAnalysisIds?: string[] | null;
  /** the System ID of the Interscan Application that will be used to process the Interscan Analysis */
  interscanApplicationSystemId?: string | null;
};
export type GroupedInterscanAnalysisCreateAndInitializeRequest = {
  /** A an ordered list of groups of ordered IDs for the Single Scan Analyses that will be used for the Interscan Analyses */
  singleScanAnalysisIdGroups?: string[][] | null;
  /** the System ID of the Interscan Application that will be used to process the Interscan Analysis */
  interscanApplicationSystemId?: string | null;
};
export type PlatformRole = {
  id?: string;
  /** A display name for the Platform Role */
  platformRoleName?: string | null;
};
export type PlatformUser = {
  id?: string;
  /** The unique ID associated with this user on the external Identity Provider. The user's access token
    will have this ID on it when they make authenticated calls to the server. */
  externalIdentityProviderId?: string | null;
  /** The display name for the Platform User */
  platformUserName?: string | null;
};
export type ResponseAnalysisScanAnalysisViewModel = {
  /** The ID of the Scan Analysis from which this View Model was generated */
  scanAnalysisId?: string;
  /** The System ID of the Single Scan Application associated with the report */
  singleScanApplicationSystemId?: string | null;
  /** The name of the Timepoint associated with this Scan Analysis */
  timepointName?: string | null;
  /** The date and time of the Exam this Scan Analysis is associated with */
  examDate?: string;
  /** The full path (relative to the Scan Processing file share) of the Coronal Reference image showing the
    labelled ROIs for this scan */
  coronalReferenceImageLocation?: string | null;
  /** The full path (relative to the Scan Processing file share) of the Sagittal Reference image showing the
    labelled ROIs for this scan */
  sagittalReferenceImageLocation?: string | null;
  /** The full path (relative to the Scan Processing file share) of the 3D .vti Volume of the Anatomical data
    associated with this Scan Analysis */
  anatomicalVolumeImageLocation?: string | null;
  /** The full path (relative to the Scan Processing file share) of the 3D .vti Volume of the Functional data
    associated with this Scan Analysis */
  functionalVolumeImageLocation?: string | null;
  /** The full path (relative to the Scan Processing file share) of the zipped bundle of .vti polydata
    representations of the ROIs associated with this Scan Analysis */
  roiPolydataBundleLocation?: string | null;
  /** The full path (relative to the Scan Processing file share) of the 3D .vti Volume of the ROIs associated
    with this Scan Analysis */
  roiLabelMapVolumeLocation?: string | null;
  /** A container of arbitrary extra data about the Anatomical Scan */
  anatomicalScanMetadata?: {
    [key: string]: string | null;
  } | null;
  /** A container of arbitrary extra data about the Anatomical Scan */
  functionalScanMetadata?: {
    [key: string]: string | null;
  } | null;
  /** A container of arbitrary extra data about the Anatomical Scan */
  metadata?: {
    [key: string]: string | null;
  } | null;
  /** A container of string-value pairs representing the Organ Uptake data for this Scan Analysis */
  organUptakeValues?: {
    [key: string]: number;
  } | null;
};
export type Roi = {
  /** The constant Sysname of the Anatomy segment where the ROI exists */
  anatomySegmentSysName?: string | null;
  /** A display name of the Anatomy segment where the ROI exists */
  anatomySegmentDisplayName?: string | null;
  /** Numeric index assigned to the ROI */
  roiIndex?: number;
  /** Measure of the maximum value (e.g. SUV, HU) detected in the ROI */
  roiMax?: number | null;
  /** Measure of the mean voxel value (e.g. SUV, HU) in the ROI */
  roiMean?: number | null;
  /** Measure of the total of all voxel values (e.g. SUV, HU) in the ROI */
  roiTotal?: number | null;
  /** Measure of the volume in cm3 in the ROI */
  roiVolume?: number | null;
  /** Measure of the standard deviation of voxel values (e.g. SUV, HU) in the ROI */
  roiStd?: number | null;
  /** Whether the ROI was not completely contained within the valid field of view for the scan */
  partial?: boolean | null;
  /** The coordinates of the ROI center */
  roiCenterCoordinates?: number[] | null;
};
export type RoiMatchClassificationMethod =
  | "NaFSuvTotal"
  | "NaFSuvMean"
  | "NaFSuvMax"
  | "DefaultSuvMax"
  | "DefaultSuvTotal"
  | "CTVolume"
  | "Concordance"
  | "Unclassified";
export type RoiMatchClassification =
  | "Unknown"
  | "New"
  | "Increasing"
  | "Stable"
  | "Decreasing"
  | "Disappeared"
  | "Unclassifiable"
  | "Scan1"
  | "Scan2"
  | "Both";
export type RoiMatchGroupViewModel = {
  /** The collection of Regions of Interest from the Pre-scan that
    are part of this ROI Match Group */
  preRois?: Roi[] | null;
  /** The collection of Regions of Interest from the Post-scan that
    are part of this ROI Match Group */
  postRois?: Roi[] | null;
  classificationMethod?: RoiMatchClassificationMethod;
  classification?: RoiMatchClassification;
};
export type PatientViewModel = {
  /** Unique identifier for the patient within the EMR system or within the scope of a clinical study */
  patientIdentifier?: string | null;
  /** The Sex associated with the Patient */
  sex?: string | null;
  /** Weight of the patient as received from the DICOM. Normally in KG */
  weight?: number | null;
  /** A container of arbitrary extra data about the Patient */
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type RoiMatchGroupTabularViewModel = {
  classification?: RoiMatchClassification;
  /** The collection of Regions of Interest from the Pre-scan that
    are part of this ROI Match Group */
  scan1RoiLocation?: string | null;
  /** A comma separated string of the ROI reference numbers for the Pre-scan ROIs included in this row */
  scan1RoiReferenceNumbers?: string | null;
  /** The collection of Regions of Interest from the Post-scan that
    are part of this ROI Match Group */
  scan2RoiLocation?: string | null;
  /** A comma separated string of the ROI reference numbers for the Post-scan ROIs included in this row */
  scan2RoiReferenceNumbers?: string | null;
  /** A comma separated string of the ROI center coordinate component corresponding to their slice numbers for
    the Pre scan grouping */
  scan1RoiSliceNumbers?: string | null;
  /** A comma separated string of the ROI center coordinate component corresponding to their slice numbers for
    the Post scan grouping */
  scan2RoiSliceNumbers?: string | null;
  /** The sum of the SUV total values for all pre-scan ROIs in this ROI match group */
  scan1SuvTotal?: number | null;
  /** The sum of the SUV total values for all post-scan ROIs in this ROI match group */
  scan2SuvTotal?: number | null;
  /** The percent of the whole body Pre-Scan SUV total that this ROI match group represents */
  scan1SuvTotalPercentOfWhole?: number | null;
  /** The percent of the whole body Post-Scan SUV total that this ROI match group represents */
  scan2SuvTotalPercentOfWhole?: number | null;
  /** The percent of the whole body Pre-Scan Volume that this ROI match group represents */
  scan1VolumePercentOfWhole?: number | null;
  /** The percent of the whole body Post-Scan Volume that this ROI match group represents */
  scan2VolumePercentOfWhole?: number | null;
  /** The percent change in the sum of the Volume between the pre-scan and post-scan ROIs in this ROI match group */
  percentChangeVolume?: number | null;
  /** The change in the sum of the Volume between the pre-scan and post-scan ROIs in this ROI match group */
  changeVolume?: number | null;
  /** The percent change in the sum of the SUV totals between the pre-scan and post-scan ROIs in this ROI match group */
  percentChangeSuvTotal?: number | null;
  /** The change in the sum of the SuvTotal between the pre-scan and post-scan ROIs in this ROI match group */
  changeSuvTotal?: number | null;
  /** The mean of the SUV mean values for all pre-scan ROIs in this ROI match group */
  scan1SuvMean?: number | null;
  /** The mean of the SUV mean values for all post-scan ROIs in this ROI match group */
  scan2SuvMean?: number | null;
  /** The percent change in the sum of the SUV Mean between the pre-scan and post-scan ROIs in this ROI match group */
  percentChangeSuvMean?: number | null;
  /** The change in the sum of the SUV Mean between the pre-scan and post-scan ROIs in this ROI match group */
  changeSuvMean?: number | null;
  /** The total of the volumes for all pre-scan ROIs in this ROI match group */
  scan1Volume?: number | null;
  /** The total of the volumes values for all post-scan ROIs in this ROI match group */
  scan2Volume?: number | null;
  /** The sum of the SUV max values for all pre-scan ROIs in this ROI match group */
  scan1SuvMax?: number | null;
  /** The sum of the SUV max values for all post-scan ROIs in this ROI match group */
  scan2SuvMax?: number | null;
  /** The percent change in the sum of the SUV Max between the pre-scan and post-scan ROIs in this ROI match group */
  percentChangeSuvMax?: number | null;
  /** The change in the sum of the SUV Max between the pre-scan and post-scan ROIs in this ROI match group */
  changeSuvMax?: number | null;
  /** The sum of the SUV Hetero values for all pre-scan ROIs in this ROI match group */
  scan1SuvHetero?: number | null;
  /** The sum of the SUV Hetero values for all post-scan ROIs in this ROI match group */
  scan2SuvHetero?: number | null;
  /** The percent change in the sum of the SUV Hetero between the pre-scan and post-scan ROIs in this ROI match group */
  percentChangeSuvHetero?: number | null;
  /** The change in the sum of the SUV Hetero between the pre-scan and post-scan ROIs in this ROI match group */
  changeSuvHetero?: number | null;
};
export type ChangeAnalysisDerivedReportData = {
  /** The collection of ROI Matches found for this Response Analysis, grouped and classified in a tabular format
    for ease of use in tables */
  tabularRoiMatchGroups?: RoiMatchGroupTabularViewModel[] | null;
  /** The difference between the sums of SUV total in the post scan in the pre scan */
  changeInOverallBurden?: number | null;
  /** The difference between the SUV total in the post scan in the pre scan expressed as a percentage */
  percentChangeInOverallBurden?: number | null;
  /** The SUV Mean calculated for the whole body by voxel in the pre scan */
  scan1WholeBodySuvMeanByVoxel?: number | null;
  /** The SUV Mean calculated for the whole body by voxel in the post scan */
  scan2WholeBodySuvMeanByVoxel?: number | null;
  changeInWholeBodySuvMeanByVoxel?: number | null;
  percentChangeWholeBodySuvMeanByVoxel?: number | null;
  /** The sum SUV Total calculated for the whole body in the pre scan */
  scan1WholeBodySuvTotal?: number | null;
  /** The sum SUV Total calculated for the whole body in the post scan */
  scan2WholeBodySuvTotal?: number | null;
  /** The Max SUV value across the whole bode for Scan1 */
  scan1WholeBodySuvMax?: number | null;
  /** The Max SUV value across the whole bode for Scan2 */
  scan2WholeBodySuvMax?: number | null;
  /** The change in the Max SUV value across the whole bode between the two scans */
  changeInWholeBodySuvMax?: number | null;
  /** The percent change in the Max SUV value across the whole bode between the two scans */
  percentChangeWholeBodySuvMax?: number | null;
  /** The sum ROI Volume calculated for the whole body in the pre scan */
  scan1WholeBodyRoiVolume?: number | null;
  /** The sum ROI Volume calculated for the whole body in the post scan */
  scan2WholeBodyRoiVolume?: number | null;
  /** The change in the volume across the whole bode between the two scans */
  changeInWholeBodyRoiVolume?: number | null;
  /** The percent change in the volume across the whole bode between the two scans */
  percentChangeWholeBodyRoiVolume?: number | null;
  /** The difference between the number of ROIs in the post scan and those in the pre scan */
  changeInNumberOfRois?: number | null;
  /** The number of ROI Matches classified as New */
  numberOfNewRoiMatchGroups?: number | null;
  /** The % of the whole body SUV total made up of the SUV total of the ROIs classified as New in the post scan */
  scan2PercentOfWholeSuvTotalNew?: number | null;
  /** The number of ROIMatches classified as Increasing */
  numberOfIncreasingRoiMatchGroups?: number | null;
  /** The % of the whole body SUV total made up of the SUV total of the ROIs classified as Increasing in the post scan */
  scan2PercentOfWholeSuvTotalIncreasing?: number | null;
  /** The number of ROI Matches classified as Stable */
  numberOfStableRoiMatchGroups?: number | null;
  /** The % of the whole body SUV total made up of the SUV total of the ROIs classified as Stable in the post scan */
  scan2PercentOfWholeSuvTotalStable?: number | null;
  /** The number of ROI Matches classified as Decreasing */
  numberOfDecreasingRoiMatchGroups?: number | null;
  /** The % of the whole body SUV total made up of the SUV total of the ROIs classified as Decreasing in the post scan */
  scan2PercentOfWholeSuvTotalDecreasing?: number | null;
  /** The number of ROI Matches classified as Disappeared */
  numberOfDisappearedRoiMatchGroups?: number | null;
  /** The number of ROI matches classified as Scan1 */
  numberOfScan1RoiMatchGroups?: number | null;
  /** The number of ROI Matches classified as Scan2 */
  numberOfScan2RoiMatchGroups?: number | null;
  /** The number of ROI Matches classified as Scan2 */
  numberOfBothRoiMatchGroups?: number | null;
  /** The number of ROI Matches classified as Unclassifiable */
  numberOfUnclassifiableRoiMatchGroups?: number | null;
  /** A display alias for Scan1 */
  scan1DisplayAlias?: string | null;
  /** A display alias for Scan2 */
  scan2DisplayAlias?: string | null;
  /** An abbreviated display alias for Scan1 */
  scan1DisplayAliasAbbreviated?: string | null;
  /** An abbreviated display alias for Scan2 */
  scan2DisplayAliasAbbreviated?: string | null;
  /** For Concordance reports - the percent by volume of ROIs that were positive in scan 1 (scan1 + both / scan2-only) */
  concordancePercentVolumeScan1Positive?: number | null;
  /** For Concordance reports - the percent by volume of ROIs that were positive in scan 2 (scan2 + both / scan1-only) */
  concordancePercentVolumeScan2Positive?: number | null;
  /** For Concordance reports - the percent by count of ROIs that were positive in scan 1 (scan1 + both / scan2-only) */
  concordancePercentCountScan1Positive?: number | null;
  /** For Concordance reports - the percent by count of ROIs that were positive in scan 2 (scan2 + both / scan1-only) */
  concordancePercentCountScan2Positive?: number | null;
  /** A dictionary of organ uptake values for the pre scan. The key is the organ name and the value is the uptake value expressed as SUVMean */
  scan1OrganUptakeValues?: {
    [key: string]: number;
  } | null;
  /** A dictionary of organ uptake values for the post scan. The key is the organ name and the value is the uptake value expressed as SUVMean */
  scan2OrganUptakeValues?: {
    [key: string]: number;
  } | null;
};
export type ResponseAnalysisReport = {
  id?: string;
  /** The ID of the Interscan Analysis associated with the report */
  interscanAnalysisId?: string;
  /** The Compound Interscan Analysis, if any, associated with the report */
  compoundInterscanAnalysisId?: string | null;
  /** The System ID of the Interscan Application associated with the report */
  interscanApplicationSystemId?: string | null;
  /** The System ID of the Compound Interscan Application, if any, associated with the report */
  compoundInterscanApplicationSystemId?: string | null;
  /** The name of the Study this Response Analysis is associated with */
  studyName?: string | null;
  /** The exam for Scan1. If there is a PreScanGroup - it will be the exam of the first scan in the group. Otherwise
    /// it is simply the date of the PreScan's exam */
  preScanDate?: string | null;
  /** The exam for Scan2. If there is a PostScanGroup - it will be the exam of the first scan in the group. Otherwise
    it is simply the date of the PostScan's exam */
  postScanDate?: string | null;
  preScanAnalysis?: ResponseAnalysisScanAnalysisViewModel;
  postScanAnalysis?: ResponseAnalysisScanAnalysisViewModel;
  /** A Group of View Models for the first of the two Scan Analyses for this Response Report, for
    InterscanAnalyses that support groups of Scan Analyses */
  preScanAnalysisGroup?: ResponseAnalysisScanAnalysisViewModel[] | null;
  /** A Group of View Models for the second of the two Scan Analyses for this Response Report, for
    InterscanAnalyses that support groups of Scan Analyses */
  postScanAnalysisGroup?: ResponseAnalysisScanAnalysisViewModel[] | null;
  /** The collection of ROI Matches found for this Response Analysis, grouped and classified */
  roiMatchGroups?: RoiMatchGroupViewModel[] | null;
  patient?: PatientViewModel;
  /** A text summary of the response analysis results */
  summaryText?: string | null;
  /** The full path (relative to the Scan Processing file share) of the Coronal Response MIP image showing
    the classified ROIs for this analysis */
  coronalResponseMipLocation?: string | null;
  /** The full path (relative to the Scan Processing file share) of the Sagittal Response MIP image showing
    the classified ROIs for this analysis */
  sagittalResponseMipLocation?: string | null;
  roiMatchClassificationMethod?: RoiMatchClassificationMethod;
  /** An identifier for the version of the schema defining the document's structure */
  schemaVersion?: number;
  /** An identifier for the document's revision */
  revision?: number;
  createdDate?: string;
  deleted?: boolean | null;
  deletedDate?: string | null;
  /** A container of arbitrary extra data about the Manifest */
  metadata?: {
    [key: string]: string | null;
  } | null;
  changeAnalysisDerivedReportData?: ChangeAnalysisDerivedReportData;
};
export type ResponseAnalysisReportCreationRequest = {
  /** ID of the interscan analysis to create a report for */
  interscanAnalysisId?: string;
};
export type ReportLightViewModel = {
  id?: string;
  /** The ID of the Interscan Analysis associated with the report */
  interscanAnalysisId?: string;
  /** The Compound Interscan Analysis, if any, associated with the report */
  compoundInterscanAnalysisId?: string | null;
  /** The System ID of the Interscan Application associated with the report */
  interscanApplicationSystemId?: string | null;
  /** The System ID of the Compound Interscan Application, if any, associated with the report */
  compoundInterscanApplicationSystemId?: string | null;
  compoundInterscanApplicationDisplayName?: string | null;
  interscanApplicationDisplayName?: string | null;
  scan1ExamDate?: string | null;
  scan2ExamDate?: string | null;
  /** The name of the Study this Response Analysis is associated with */
  studyName?: string | null;
  /** Unique identifier for the patient within the EMR system or within the scope of a clinical study */
  patientIdentifier?: string | null;
  /** An identifier for the document's revision */
  revision?: number;
  createdDate?: string;
};
export type ScanAnalysisStepTransitionRequest = {
  /** The ID of the current Scan Analysis Step for the Scan Analysis to transition from */
  currentStepId?: string;
  /** The System ID of the Step to transition to */
  targetStepSystemId?: string | null;
};
export type ScanAnalysisStepArtifactViewModel = {
  /** The ID of the Scan Processing Task this artifact is associated with. May be null in some cases. */
  taskId?: string | null;
  /** The System ID of the Output Template this artifact is associated with within the Application Template */
  systemId?: string | null;
  /** The file name of the Artifact */
  fileName?: string | null;
  /** The relative path to the Artifact within the cloud file share */
  relativePath?: string | null;
  /** A URI providing access to the artifact. This URI will expire after a set amount of time as noted by the
    UriExpiration field */
  temporaryUri?: string | null;
  /** The expiration date/time of the temporary URI */
  uriExpiration?: string | null;
  /** This boolean indicates whether the system actually found the expected artifact in the expected location. */
  success?: boolean;
  /** In cases where multiple outputs are specified in a single argument in the SPAT, this index tells you
    the index of this artifact in that list. Most often 0 or 1 representing the first or second. Defaults
    to 0 in the case of a simple single output. */
  templateArgumentIndex?: number;
};
export type ScanAnalysisStepManualCompletionRequest = {
  /** The ID of the Scan Analysis Step to manually mark as complete. */
  stepId?: string;
};
export type ScanModality = {
  id?: string;
  /** Short label for the modality */
  scanModalityLabel?: string | null;
  /** Full name of the modality */
  scanModalityName?: string | null;
  /** Sequence number for use in display/layout control */
  scanModalitySequence?: number;
};
export type ScanPackageProcessRequest = {
  scanPackageLocation?: string | null;
  /** Optional - the ID of the Study to ingest data into. If missing it will fall back to the Study ID or Name
    in the manifest.json. */
  studyId?: string | null;
  /** Optional - The SystemID of the Single Scan Application to be used to process the scans in this manifest
    If missing it will fall back to the value the manifest.json. */
  singleScanApplicationSystemId?: string | null;
  /** Optional. If true, the original Manifest will be overwritten with the updated one per processing. Defaults to true. */
  overwriteOriginal?: boolean;
};
export type ScanPackageManifestTimepoint = {
  /** The ID of the exam, if there is one. Only populated if the exam already exists */
  id?: string | null;
  /** The name/label of the timepoint */
  timepointName?: string | null;
  /** Numeric sequence indicating the order of timepoints in the protocol */
  sequence?: number;
};
export type ScanPackageManifestScan = {
  /** The ID of the scan, if there is one. Only populated if the scan already exists */
  id?: string | null;
  /** The Label for the Scan */
  label?: string | null;
  /** The Series for the Scan */
  series?: string | null;
  /** The label of the Modality for the scan */
  modalityLabel?: string | null;
  /** The relative path to the scan files within the manifest */
  path?: string | null;
  /** A container of arbitrary extra data about the Scan */
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type ScanPackageManifestExam = {
  /** The ID of the exam, if there is one. Only populated if the exam already exists */
  id?: string | null;
  timepoint?: ScanPackageManifestTimepoint;
  /** The date the exam took place */
  examDate?: string | null;
  /** The scans associated with this exam */
  scans?: ScanPackageManifestScan[] | null;
  /** A container of arbitrary extra data about the Exam */
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type ScanPackageManifestPatient = {
  /** The ID of the patient, if there is one. Only populated if the patient already exists */
  id?: string | null;
  /** The patient's identifier */
  identifier?: string | null;
  /** The code corresponding to the patient's sex (M/F/U) */
  sexCode?: string | null;
  /** The patient's weight in Kilograms */
  weightKg?: number | null;
  /** The Exams associated with this patient */
  exams?: ScanPackageManifestExam[] | null;
  /** A container of arbitrary extra data about the Patient */
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type ScanPackageManifest = {
  /** The SystemID of the Single Scan Application to be used to process the scans in this manifest */
  singleScanApplicationSystemId?: string | null;
  /** If true this flag indicates that this Manifest has already been processed */
  processed?: boolean | null;
  /** The Study this Scan Package is associated with. Optional - if no Study ID is provided, the
    system will attempt to match the Study by name */
  studyId?: string | null;
  /** The name of the Study this Scan Package is associated with. Optional - if no Study ID is provided, the
    system will attempt to match the Study by name */
  studyName?: string | null;
  /** The Patients associated with this study */
  patients?: ScanPackageManifestPatient[] | null;
  /** A container of arbitrary extra data about the Manifest */
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type ScanPackageValidationResult = {
  /** True if the validation succeeded, else false */
  success?: boolean;
  originalManifest?: ScanPackageManifest;
  validatedManifest?: ScanPackageManifest;
};
export type ScanPackageValidationResultRead = {
  /** The new patients identified in the manifest */
  newPatients?: Patient[] | null;
  /** The existing patients identified in the manifest */
  existingPatients?: Patient[] | null;
  /** The new Exams identified in the manifest */
  newExams?: Exam[] | null;
  /** The existing Exams identified in the manifest */
  existingExams?: Exam[] | null;
  /** The new Timepoints identified in the manifest */
  newTimepoints?: Timepoint[] | null;
  /** The existing Timepoints identified in the manifest */
  existingTimepoints?: Timepoint[] | null;
  /** The new Scans identified in the manifest */
  newScans?: Scan[] | null;
  /** The existing Scans identified in the manifest */
  existingScans?: Scan[] | null;
  /** A list of errors encountered when validating the manifest, if any */
  errorMessages?: string[] | null;
  /** A list of warnings encountered when validating the manifest, if any */
  warningMessages?: string[] | null;
  /** True if the validation succeeded, else false */
  success?: boolean;
  originalManifest?: ScanPackageManifest;
  validatedManifest?: ScanPackageManifest;
};
export type ScanProcessingTaskArgumentType =
  | "Input"
  | "Output"
  | "Extra"
  | "Unknown";
export type ScanProcessingTaskArgument = {
  id?: string;
  /** The ID of the Scan Processing Task this Argument is associated with */
  scanProcessingTaskId?: string;
  /** The name of the argument */
  argumentName?: string | null;
  /** The value of the argument */
  argumentValue?: string | null;
  argumentType?: ScanProcessingTaskArgumentType;
};
export type ScanProcessingTaskStartRequest = {
  /** The ID of the Task to start */
  scanProcessingTaskId?: string;
};
export type ArtifactSideLoadHistoryViewModel = {
  /** The ID of the Scan Processing Task this artifact is associated with. May be null in some cases. */
  taskId?: string | null;
  /** The System ID of the Output Template this artifact is associated with within the Application Template */
  systemId?: string | null;
  /** The file name of the Artifact */
  fileName?: string | null;
  /** The relative path to the Artifact within the cloud file share */
  relativePath?: string | null;
  /** A URI providing access to the artifact. This URI will expire after a set amount of time as noted by the
    UriExpiration field */
  temporaryUri?: string | null;
  /** The expiration date/time of the temporary URI */
  uriExpiration?: string | null;
  /** This boolean indicates whether the system actually found the expected artifact in the expected location. */
  success?: boolean;
  /** In cases where multiple outputs are specified in a single argument in the SPAT, this index tells you
    the index of this artifact in that list. Most often 0 or 1 representing the first or second. Defaults
    to 0 in the case of a simple single output. */
  templateArgumentIndex?: number;
  /** The Date/Time the Side-load occurred */
  sideLoadDateTime?: string | null;
  /** The notes from the metadata field, should contain the SAF if the side-load was done correctly */
  sideLoadReason?: string | null;
  /** The user who side-loaded the artifact */
  sideLoadUser?: string | null;
};
export type Sex = {
  id?: string;
  /** Short label for sex (e.g. M, F) */
  sexCode?: string | null;
  /** A display label for the Sex */
  sexLabel?: string | null;
};
export type ScanAnalysisViewModelPagedResult = {
  /** The resulting subset of items */
  result?: ScanAnalysisViewModel[] | null;
  /** The page number of this subset of results */
  pageNumber?: number | null;
  /** The size of the pages in this paged result */
  pageSize?: number | null;
  /** The total count of objects in the entire collection */
  totalCount?: number;
};
export type ScanAnalysisViewModelPagedResultRead = {
  /** The resulting subset of items */
  result?: ScanAnalysisViewModelRead[] | null;
  /** The page number of this subset of results */
  pageNumber?: number | null;
  /** The size of the pages in this paged result */
  pageSize?: number | null;
  /** The total count of objects in the entire collection */
  totalCount?: number;
};
export type InterscanAnalysisViewModelPagedResult = {
  /** The resulting subset of items */
  result?: InterscanAnalysisViewModel[] | null;
  /** The page number of this subset of results */
  pageNumber?: number | null;
  /** The size of the pages in this paged result */
  pageSize?: number | null;
  /** The total count of objects in the entire collection */
  totalCount?: number;
};
export type InterscanAnalysisViewModelPagedResultRead = {
  /** The resulting subset of items */
  result?: InterscanAnalysisViewModelRead[] | null;
  /** The page number of this subset of results */
  pageNumber?: number | null;
  /** The size of the pages in this paged result */
  pageSize?: number | null;
  /** The total count of objects in the entire collection */
  totalCount?: number;
};
export type CompoundInterscanAnalysisViewModelPagedResult = {
  /** The resulting subset of items */
  result?: CompoundInterscanAnalysisViewModel[] | null;
  /** The page number of this subset of results */
  pageNumber?: number | null;
  /** The size of the pages in this paged result */
  pageSize?: number | null;
  /** The total count of objects in the entire collection */
  totalCount?: number;
};
export type CompoundInterscanAnalysisViewModelPagedResultRead = {
  /** The resulting subset of items */
  result?: CompoundInterscanAnalysisViewModelRead[] | null;
  /** The page number of this subset of results */
  pageNumber?: number | null;
  /** The size of the pages in this paged result */
  pageSize?: number | null;
  /** The total count of objects in the entire collection */
  totalCount?: number;
};
export type StudyStatus = {
  id?: string;
  /** A display label for the Study Status */
  studyStatusLabel?: string | null;
  /** Numeric sequence, for display/layout control */
  studyStatusSequence?: number;
};
export type Tracer = {
  id?: string;
  /** Label for the tracer, for display and lookup */
  tracerLabel?: string | null;
  /** Long name for tracer, to be used in reports or display */
  tracerName?: string | null;
  /** Numeric sequence for tracer, for layout/display control */
  tracerSequence?: number;
};
export const {
  useGetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsQuery,
  usePostApiCompoundInterscanAnalysesCreateAndInitializeMutation,
  usePostApiCompoundInterscanAnalysesMutation,
  useGetApiCompoundInterscanAnalysesQuery,
  usePutApiCompoundInterscanAnalysesByIdMutation,
  useDeleteApiCompoundInterscanAnalysesByIdMutation,
  useGetApiCompoundInterscanAnalysesByIdQuery,
  useGetApiCompoundInterscanAnalysisViewModelsByIdQuery,
  useGetApiCompoundInterscanApplicationsQuery,
  useGetApiConfigurationQuery,
  useGetApiConfigurationBuildInfoQuery,
  usePostApiExamsByIdScansMutation,
  useGetApiExamsByIdScansQuery,
  usePostApiExamsMutation,
  useGetApiExamsQuery,
  usePutApiExamsByIdMutation,
  useDeleteApiExamsByIdMutation,
  useGetApiExamsByIdQuery,
  useGetApiIdsPatientsQuery,
  useGetApiIdsStudyQuery,
  useGetApiIdsTimepointsQuery,
  usePostApiInterscanAnalysesCreateAndInitializeUngroupedMutation,
  usePostApiInterscanAnalysesCreateAndInitializeGroupedMutation,
  useGetApiInterscanAnalysesByIdScanAnalysisViewModelsQuery,
  usePostApiInterscanAnalysesMutation,
  useGetApiInterscanAnalysesQuery,
  usePutApiInterscanAnalysesByIdMutation,
  useDeleteApiInterscanAnalysesByIdMutation,
  useGetApiInterscanAnalysesByIdQuery,
  useGetApiInterscanAnalysisViewModelsByIdQuery,
  useGetApiInterscanApplicationsQuery,
  useGetApiPatientsByIdExamsQuery,
  usePostApiPatientsMutation,
  useGetApiPatientsQuery,
  usePutApiPatientsByIdMutation,
  useDeleteApiPatientsByIdMutation,
  useGetApiPatientsByIdQuery,
  useGetApiPlatformRolesByIdQuery,
  useGetApiPlatformRolesQuery,
  useGetApiPlatformUsersSelfQuery,
  useGetApiPlatformUsersByIdRolesQuery,
  usePostApiPlatformUsersByIdRolesMutation,
  useDeleteApiPlatformUsersByIdRolesAndRoleIdMutation,
  usePostApiPlatformUsersMutation,
  useGetApiPlatformUsersQuery,
  usePutApiPlatformUsersByIdMutation,
  useDeleteApiPlatformUsersByIdMutation,
  useGetApiPlatformUsersByIdQuery,
  usePostApiResponseAnalysisReportsGenerateMutation,
  useGetApiResponseAnalysisReportsLightViewModelsQuery,
  usePostApiResponseAnalysisReportsMutation,
  useGetApiResponseAnalysisReportsQuery,
  usePutApiResponseAnalysisReportsByIdMutation,
  useDeleteApiResponseAnalysisReportsByIdMutation,
  useGetApiResponseAnalysisReportsByIdQuery,
  useGetApiScanAnalysesByScansQuery,
  useGetApiScanAnalysesByIdStepsQuery,
  usePostApiScanAnalysesMutation,
  useGetApiScanAnalysesQuery,
  usePutApiScanAnalysesByIdMutation,
  useDeleteApiScanAnalysesByIdMutation,
  useGetApiScanAnalysesByIdQuery,
  usePostApiScanAnalysisStepsByIdTasksMutation,
  useGetApiScanAnalysisStepsByIdTasksQuery,
  usePostApiScanAnalysisStepsByIdTransitionMutation,
  useGetApiScanAnalysisStepsByIdArtifactUrlsQuery,
  usePostApiScanAnalysisStepsByIdManuallyMarkCompleteMutation,
  usePostApiScanAnalysisStepsMutation,
  useGetApiScanAnalysisStepsQuery,
  usePutApiScanAnalysisStepsByIdMutation,
  useDeleteApiScanAnalysisStepsByIdMutation,
  useGetApiScanAnalysisStepsByIdQuery,
  useGetApiScanAnalysisViewModelsByIdQuery,
  useGetApiScanModalitiesByIdQuery,
  useGetApiScanModalitiesQuery,
  usePostApiScanpackagesProcessRequestMutation,
  usePostApiScanpackagesValidationMutation,
  usePostApiScanpackagesValidateManifestFileMutation,
  useGetApiScanProcessingOutputsTemporaryUriByRelativeFilePathQuery,
  useGetApiScanProcessingOutputsJsonContentByRelativePathQuery,
  useGetApiScanProcessingTaskArgumentsByIdQuery,
  useGetApiScanProcessingTaskArgumentsQuery,
  usePostApiScanProcessingTasksByIdRequestsStartMutation,
  useGetApiScanProcessingTasksByIdDtoQuery,
  useGetApiScanProcessingTasksByIdStatusesQuery,
  usePostApiScanProcessingTasksByIdSingleStepOutputArtifactFileMutation,
  usePostApiScanProcessingTasksByIdSingleStepOutputArtifactTextMutation,
  useGetApiScanProcessingTasksByIdSingleScanInputSourceUrlQuery,
  useGetApiScanProcessingTasksByIdArtifactSideloadHistoryQuery,
  usePostApiScanProcessingTasksMutation,
  useGetApiScanProcessingTasksQuery,
  usePutApiScanProcessingTasksByIdMutation,
  useDeleteApiScanProcessingTasksByIdMutation,
  useGetApiScanProcessingTasksByIdQuery,
  useGetApiScanProcessingTaskStatusesByIdQuery,
  useGetApiScanProcessingTaskStatusesQuery,
  useGetApiScanProcessingTaskStatusTypesByIdQuery,
  useGetApiScanProcessingTaskStatusTypesQuery,
  useGetApiScansByIdExamQuery,
  usePostApiScansMutation,
  useGetApiScansQuery,
  usePutApiScansByIdMutation,
  useDeleteApiScansByIdMutation,
  useGetApiScansByIdQuery,
  useGetApiSexesByIdQuery,
  useGetApiSexesQuery,
  useGetApiSingleScanApplicationsQuery,
  useGetApiStudiesByIdExamsQuery,
  usePostApiStudiesByIdPatientsAndPatientIdMutation,
  useGetApiStudiesByIdPatientsQuery,
  useGetApiStudiesByIdTimepointsQuery,
  useGetApiStudiesByIdScanProcessingTaskStatusesQuery,
  useGetApiStudiesByIdScanAnalysisViewModelsQuery,
  useGetApiStudiesByIdScanAnalysisViewModelsByIdsQuery,
  useGetApiStudiesByIdInterscanAnalysisViewModelsQuery,
  useGetApiStudiesByIdCompoundInterscanAnalysisViewModelsQuery,
  usePostApiStudiesMutation,
  useGetApiStudiesQuery,
  usePutApiStudiesByIdMutation,
  useDeleteApiStudiesByIdMutation,
  useGetApiStudiesByIdQuery,
  useGetApiStudyStatusesByIdQuery,
  useGetApiStudyStatusesQuery,
  usePostApiTimepointsMutation,
  useGetApiTimepointsQuery,
  usePutApiTimepointsByIdMutation,
  useDeleteApiTimepointsByIdMutation,
  useGetApiTimepointsByIdQuery,
  useGetApiTracersByIdQuery,
  useGetApiTracersQuery,
} = injectedRtkApi;
