import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Theme,
  Typography,
  Grid,
  Container,
  Backdrop,
  CircularProgress,
  IconButton,
  Button,
  Modal,
  Paper,
  useTheme,
  Box,
  Pagination,
} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { StudySelectMenu } from "./StudySelectMenu";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { Cached } from "@mui/icons-material";
import { AiqTooltip } from "../common/AiqToolTip";
import { SelectedStudy } from "../../app/selectors/studySelectors";
import {
  useGetApiStudiesQuery,
  Study,
  useDeleteApiInterscanAnalysesByIdMutation, 
  useGetApiInterscanApplicationsQuery} from "../../app/api/aiq-api";
import {
  SelectedStudyInterscanAnalysisVMsSelector,
  SelectedStudyInterscanAnalysesLoadingSelector,
  SelectedStudyInterscanAnalysesTotalCountSelector } from "../../app/selectors/interscanAnalysisSelectors";
import { InterscanProcessingLanes } from "./InterscanProcessingLanes";
import { enhancedApi } from "../../app/api/enhancedApi";
import { AiqConfigurations } from "../../redux/aiqConfigurations";
import { InterscanCreatePopup } from "./InterscanCreatePopup";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";
import { Toast } from "../common/Toast";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { setInterscanAnalysisPageNumber, setInterscanAnalysisPatientFilter, setSelectedStudy } from "../../app/slices/scanAnalysisSlice";
import { FilterMenu } from "./FilterMenu";
import { useRef } from "react";
import { AllInterscanApplicationsSelector } from "../../app/selectors/applicationSelectors";
/**
 * This is a connected component that displays a list of Platform Studies
 */
export const InterscanProcessingDashboard: React.FunctionComponent = (props) => {
  const history = useNavigate();
  const [params] = useSearchParams()
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [showAddInterscanAnalysisModal, setShowAddInterscanAnalysisModal] = React.useState<boolean>(false);
  const [showStudySelectModal, setShowStudySelectModal] = React.useState<boolean>(false);
  const {data: studies, isLoading: studiesLoading} = useGetApiStudiesQuery();
  const selectedStudy = useSelector((state: ApplicationState) => SelectedStudy(state as never));
  const interscanAnalysisVMs = useSelector((state: ApplicationState) => SelectedStudyInterscanAnalysisVMsSelector(state as never));
  const interscanAnalysisVMsLoading = useSelector((state: ApplicationState) => SelectedStudyInterscanAnalysesLoadingSelector(state as never));
  const [loadInterscanAnalysisVMs, loadInterscanAnalysisResult] = enhancedApi.endpoints.getApiStudiesByIdInterscanAnalysisViewModels.useLazyQuery();
  const [doCreateInterscanAnalysis, createInterscanAnalysisResult] = enhancedApi.endpoints.postApiInterscanAnalysesCreateAndInitializeUngrouped.useMutation();
  const dispatch = useDispatch();
  const [modalSelectedStudy, setModalSelectedStudy] = React.useState<Study | undefined> (undefined);
  const theme = useTheme();
  const [deletecall, deleteResult] = useDeleteApiInterscanAnalysesByIdMutation({fixedCacheKey: 'shared-interscan-analysis-delete'});
  const interscanApplications = useSelector((state: ApplicationState) => AllInterscanApplicationsSelector(state as never));
  useGetApiInterscanApplicationsQuery();

  // Pagination and Filtering State
  const interscanPageNumber: number = useSelector((state: ApplicationState) => state.scanAnalyses.interscanAnalysisPageNumber);
  const interscanPageSize: number = useSelector((state: ApplicationState) => state.scanAnalyses.interscanAnalysisPageSize);
  const interscanPatientFilter: string | undefined = useSelector((state: ApplicationState) => state.scanAnalyses.interscanAnalysisPatientFilter);
  const interscanTotalCount = useSelector(SelectedStudyInterscanAnalysesTotalCountSelector)

  const updateUrlWithStudyId = (studyId?: string | undefined) => {
    if (studyId) {
      history({
        pathname: NavigationConstants.interscanProcessingPath,
        search: `?${NavigationConstants.scanProcessingStudyIdQueryStringKey}=${studyId!}`,
      });
    }
  }

  const autoRefreshTimer = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
  const refreshTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const forceRefresh = () => {
    if (selectedStudy) {
      loadInterscanAnalysisVMs({
        id: selectedStudy.id!,
        pageNumber: interscanPageNumber,
        pageSize: interscanPageSize,
        patientIdentifierSearchPattern: interscanPatientFilter
      });
    }
  }

  const createAutoRefreshTimer = () => {
    console.log("Creating auto-refresh timer for interscan processing dashboard");
    autoRefreshTimer.current = setInterval(() => {
      console.log("Auto-refreshing interscan processing dashboard");
      forceRefresh();
    }, AiqConfigurations.scanStatusRefreshIntervalSecs * 1000);
    return () => clearInterval(autoRefreshTimer.current);
  };

  const destroyAutoRefreshTimer = () => {
    if (autoRefreshTimer.current) {
      console.log("Destroying auto-refresh timer for interscan processing dashboard");
      clearInterval(autoRefreshTimer.current);
      autoRefreshTimer.current = undefined;
    }
  };

  const resetAutoRefreshTimer = (refreshImmediately: boolean = false) => {
    destroyAutoRefreshTimer();
    if (refreshImmediately) {
      forceRefresh();
    }
    return createAutoRefreshTimer();
  };

  const createRefreshTimeout = () => {
    refreshTimeout.current = setTimeout(() => {
      forceRefresh();
    }, 1000);
    return () => clearTimeout(refreshTimeout.current);
  };

  const destroyRefreshTimeout = () => {
    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
      refreshTimeout.current = undefined;
    }
  };

  const resetRefreshTimeout = () => {
    destroyRefreshTimeout();
    return createRefreshTimeout();
  };

  React.useEffect(() => {
    // On initial load, check the query string for a study id. If there is one, set the selected study to that ID
    if (params.has("studyId")) {
      const studyIdFromUrl = params.get("studyId") as string;
      dispatch(setSelectedStudy(studyIdFromUrl));
    }

    return () => {
      destroyRefreshTimeout();
      destroyAutoRefreshTimer();
    };
  }, []);

  React.useEffect(() => {
    // If the selected study has changed, set the URL query string to reflect it and load the
    // scan analysis view models for the new study
    if (selectedStudy) {
      updateUrlWithStudyId(selectedStudy.id!);
      resetAutoRefreshTimer(true);
    }
  }, [selectedStudy]);

  React.useEffect(() => {
    // If a scan analysis has been deleted, reload the scan analysis VMs
    if (selectedStudy && deleteResult.isSuccess) {
      console.log("Scan analysis deleted, reloading scan analysis VMs");
      deleteResult.reset();
      // Add a delay to make sure the back end has totally processed the delete before requerying
      resetRefreshTimeout();
    }
  }, [deleteResult]);

  React.useEffect(() => {
    // TODO: Factor out into seperate component
    // When the studies have loaded, if there is no selcted study
    // we need to select one.
    if(!selectedStudy && studies && studies.length > 0) {
      // If there is only one study, select that one
      if(studies.length == 1) {
        dispatch(setSelectedStudy(studies[0].id!));
        updateUrlWithStudyId(studies[0].id!);
      } else {
        // if there are multiple studies, show the study select menu
        setModalSelectedStudy([...studies].sort((a: Study, b: Study) => {
          if (
            (a.studyName as string).toLocaleLowerCase() <
            (b.studyName as string).toLocaleLowerCase()
          ) {
            return -1;
          }
          if (
            (a.studyName as string).toLocaleLowerCase() >
            (b.studyName as string).toLocaleLowerCase()
          ) {
            return 1;
          }
          return 0;
        })[0]);
        setShowStudySelectModal(true);
      }
    }
  }, [studies]);

  React.useEffect(() => {
    if(createInterscanAnalysisResult.isSuccess) {
      setSnackbarText("Interscan Analysis created!");
    }
    else if (createInterscanAnalysisResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(createInterscanAnalysisResult);
      setSnackbarText(`An error occurred while trying to create the Interscan Analysis - ${errorMessage}`);
    }
  }, [createInterscanAnalysisResult]);

  React.useEffect(() => {
    resetAutoRefreshTimer(true);
   }, [interscanPageNumber, interscanPatientFilter])

  const handleStudySelectionChange = (selectedStudyId: string | undefined) => {
    if (selectedStudyId !== selectedStudy?.id) {
      dispatch(setSelectedStudy(selectedStudyId));
      updateUrlWithStudyId(selectedStudyId);
    }
    setShowStudySelectModal(false);
  }

  const getNumberOfPages = (): number => {
    return Math.ceil(interscanTotalCount/interscanPageSize)
   }

  // TODO: Factor out into seperate component
  const renderStudySelectModal = () => {
    return (
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          maxHeight: "100%"
        }}
        open={showStudySelectModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box data-cy="StudySelectModal" sx={{
          outline: "none",
          maxHeight: "100%",
        }}>
          {studies && (
            <Paper sx={{
              width: "600px",
              padding: "10px"
            }}>
              <Typography variant="h5">Select a Study</Typography>
              <StudySelectMenu
                studies={[...studies]}
                selectedStudy={modalSelectedStudy}
                onSelectionChanged={(studyId: string | undefined) => handleStudySelectionChange(studyId)}
              />
              <Box sx={{
                textAlign: "right",
                p: 2
              }}>
                <Button
                  data-cy="StudySelectButton"
                  variant="contained"
                  color="primary"
                  onClick={() => handleStudySelectionChange(modalSelectedStudy?.id)}
                >Select</Button>
              </Box>
            </Paper>
          )}
        </Box>
      </Modal>
    );
  };

  const renderAddInterscanAnalysisModal = () => {
    return (
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          maxHeight: "100%"
        }}
        open={showAddInterscanAnalysisModal}
        onClose={() => setShowAddInterscanAnalysisModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box data-cy="CreateInterscanProcessingPopup" sx={{
          outline: "none",
          height: "90%",
          width: "95%"
        }}>
          <InterscanCreatePopup
            onCancel={() => setShowAddInterscanAnalysisModal(false)}
            selectedStudyId={selectedStudy!.id!}
          />
        </Box>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      { selectedStudy && showAddInterscanAnalysisModal && renderAddInterscanAnalysisModal()}
      {renderStudySelectModal()}
      <Container data-cy="InterscanDashboard" maxWidth={false} disableGutters={true} sx={{height: "100%"}}>
        <Backdrop
          sx={{zIndex: theme.zIndex.drawer + 1, color: "#fff"}}
          open={ selectedStudy == undefined || (interscanAnalysisVMs.length == 0 && interscanAnalysisVMsLoading) }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <AiqTooltip title="Add new Interscan Analysis">
          <IconButton
            data-cy="AddInterscanAnalysisButton"
            sx={{
              position: "absolute",
              bottom: 20,
              right: 40
            }}
            color="primary"
            aria-label="Add"
            size="medium"
            onClick={() => setShowAddInterscanAnalysisModal(true)}
          >
            <AddCircleIcon fontSize="large" style={{fontSize: "50px", fontWeight: "lighter"}}/>
          </IconButton>
        </AiqTooltip>

        <Grid container alignItems="flex-end" justifyContent="space-between" spacing={0}>
          <Grid item xs={12} md={3}>
            <Typography variant="h4" sx={{display: "flex",
              flexFlow: "row wrap",
              width: "100%",
              marginBottom: "6px"
            }}>
              <AiqTooltip title="Refresh Processing Statuses">
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="Refresh"
                  onClick={(event) => {
                    forceRefresh();
                    event.stopPropagation();
                  }}
                >
                  <Cached />
                </IconButton>
              </AiqTooltip>
              Interscan Processing
            </Typography>
          </Grid>
          {selectedStudy && studies && (
            <React.Fragment>
              <Grid item xs={12} md={3}>
                <StudySelectMenu
                  studies={[...studies]}
                  selectedStudy={selectedStudy}
                  onSelectionChanged={(studyId: string | undefined) =>
                    handleStudySelectionChange(studyId)
                  }
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12} md={3}>
            <FilterMenu
              filterValue={interscanPatientFilter}
              onFilterChange={(updatedValue: string | undefined) => dispatch(setInterscanAnalysisPatientFilter(updatedValue))}
            />
          </Grid>
        </Grid>
        {interscanApplications && studies &&
        interscanApplications.filter((application) => interscanAnalysisVMs.filter(x => x.applicationTemplate.systemId == application.systemId).length > 0)
        .map((application) => (
            <React.Fragment key={application.systemId}>
              <Typography variant="subtitle2" sx={{display: "flex",
                flexFlow: "row wrap",
                width: "100%",
                marginBottom: "6px"
              }}>
                {application.displayName}
              </Typography>
              <InterscanProcessingLanes application={application} onStepTransition={() => forceRefresh()}/>
              {getNumberOfPages() > 1 && (
                <Box sx={{display: "flex"}}>
                  <Pagination
                    data-cy="Pagination"
                    count={getNumberOfPages()}
                    page={interscanPageNumber + 1}
                    onChange={(event: React.ChangeEvent<unknown>, value: number) => dispatch(setInterscanAnalysisPageNumber(value - 1))  }
                    sx={{marginLeft: 'auto', marginRight: 'auto'}} />
                </Box>
              )}
            </React.Fragment>
          ))}
      </Container>
      <Toast
          open={snackbarText !== undefined}
          onClose={() => setSnackbarText(undefined)}
          text={snackbarText ?? ""}
          autoHideDurationMillisecs={5000}
        />
    </React.Fragment>
  );
}
