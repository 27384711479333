import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { dateFormatWithTime, defaultDateFormat, getFormattedDateFromString } from "../../app/utilities/dateUtilities";
import { AiqTooltip } from "../common/AiqToolTip";

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function EnhancedTableHead(props: any) {
  const { order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell: any, index: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === index ? order : false}
            sx={{minWidth: headCell.width, width: headCell.width, paddingLeft: 1, paddingRight: 1, fontSize: ".75rem" }}
          >
            <TableSortLabel
              active={orderBy === index}
              direction={orderBy === index ? order : "asc"}
              onClick={createSortHandler(index)}
            >
              {headCell.label}
              {orderBy === index ? (
                <Box component="span" sx={{ 
                  border: 0,
                  clip: "rect(0 0 0 0)",
                  height: 1,
                  margin: -1,
                  overflow: "hidden",
                  padding: 0,
                  position: "absolute",
                  top: 20                        
                }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar sx= {{pl: 2, pr: 1}}>
    <Typography sx={{flex: "1 1 100%"}}  variant="h6" id="tableTitle" component="div">
        Single Scan Analyses
    </Typography>
    </Toolbar>
  );
};

export default function InterscanCreateSingleScanTable(props: any) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(0);
  const { data, headCells, maxSelect, onSelectionChange, keyRowIndex } = props;
  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: any, name: any) => {
    const selectedIndex = props.selected.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected =  props.selected.length < maxSelect ? newSelected.concat(props.selected, name) : props.selected;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(props.selected.slice(0, selectedIndex), props.selected.slice(selectedIndex + 1));
    }

    
    onSelectionChange(newSelected);
  };

  const isSelected = (name: string) => props.selected.indexOf(name) !== -1;
  const isAssigned = (name: string) => props.assigned.indexOf(name) !== -1;

  return (
    <Paper sx={{width: "100%", height: "100%", display: "flex", flexDirection: "column", mb: 2}}>
      <EnhancedTableToolbar />
      <TableContainer sx={{height: "100%", width: "100%"}}>
        <Table
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
          sx={{ width: "100%", tableLayout: "fixed"}}
        >
          <EnhancedTableHead
            headCells={headCells}
            numSelected={props.selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map((row: any, index: any) => {
              const isItemSelected = isSelected(row[keyRowIndex]);
              const isItemAssigned = isAssigned(row[keyRowIndex]);

              return (
                <TableRow
                  onClick={(event) => { if(!isItemAssigned) { handleClick(event, row[keyRowIndex]) } }}
                  data-cy="SortableTableRow"
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row[keyRowIndex]}
                  hover={!isItemAssigned}
                  selected={isItemSelected}
                >
                  {row.map((cell: any, index: number) =>
                    <AiqTooltip enterDelay={1000} enterNextDelay={1000} title={props.headCells[index].date ? getFormattedDateFromString(cell,headCells[index].time ? dateFormatWithTime : defaultDateFormat): cell}>
                      <TableCell
                        key={index}
                        sx={{
                          fontSize: ".75rem", 
                          padding: "6px 4px", 
                          width: props.headCells[index].width,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          background: (theme) => { return isItemAssigned ? theme.palette.secondary.light: "inherit" }}}
                        align={headCells[index].numeric ? 'right' : 'left'}>
                        {headCells[index].date ? getFormattedDateFromString(cell,headCells[index].time ? dateFormatWithTime : defaultDateFormat): cell}
                      </TableCell>
                    </AiqTooltip>
                  )}
                </TableRow>
              );
            })}

          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

InterscanCreateSingleScanTable.propTypes = {
  data: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  maxSelect: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  assigned:  PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  keyRowIndex: PropTypes.number.isRequired,
};
