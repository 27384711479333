import * as React from "react";
import { useEffect, useState } from "react";
import { produce } from "immer";
// TODO: Put imports back after addressing stability issues with VTK lib -KAG20230713
// import { RoiRemoval3dView } from "./RoiRemoval3dView";
import {
  Box,
  Button,
  CircularProgress,
  Grid, Link
} from "@mui/material";
import { ConfirmDeleteRoiDialog, IConfirmDeleteRoiDialogResult } from "./ConfirmDeleteRoiDialog";
import { RoiDetailsTable } from "./RoiDetailsTable";
import {
  ClientSideBaseAnalysisStepViewModel
} from "../../redux/services/view-models/ClientSideBaseAnalysisStepViewModel";
import { SingleScanApplicationTaskTemplate } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import {
  IClientSideRoiViewModel,
  loadClientSideRoiViewModels,
  mapToRawRoiDtos
} from "../../redux/services/view-models/ClientSideRoiViewModel";
import { Toast } from "../common/Toast";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import {
  ClientSideSingleScanAnalysisViewModel
} from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import {  getRoiLocationsForReporting } from "../../constants/RoiLocationConstants";
import { toast } from "react-toastify";

interface IRoiRemovalProps {
  step: ClientSideBaseAnalysisStepViewModel<SingleScanApplicationTaskTemplate>;
  scanAnalysis: ClientSideSingleScanAnalysisViewModel
}

// noinspection JSUnusedLocalSymbols
export const RoiRemoval: React.FunctionComponent<IRoiRemovalProps> = (props) => {

  //region -- React Hooks --

  const [rois, setRois] = useState<Array<IClientSideRoiViewModel> | null>(null);
  const [confirmDeleteRoiDialogOpen, setConfirmDeleteRoiDialogOpen] = useState<boolean>(false);
  const [confirmSaveRoisDialogOpen, setConfirmSaveRoisDialogOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string | null>(null);

  const {data: roisUrlData, isLoading: roisUrlIsLoading} =
    enhancedApi.endpoints.getApiScanProcessingTasksByIdSingleScanInputSourceUrl.useQuery(
      {
        id: props.step.tasks.find(v => v.taskTemplate.systemId === "ROI_REMOVAL_TASK")!.scanProcessingTask.id!,
        analysisTaskArgumentKey: 'rois_json_input'
      },
      {
        refetchOnMountOrArgChange: 600
      });

  // TODO: Put React hooks back after addressing stability issues with VTK lib -KAG20230713
  // const [cursorPosition, ] = useState<[number, number, number]>([0, 0, 0]);
  // const [, setSelectedRoiId] = useState<string | null>(null);

  // const {data: anatomicalVolumeUrlData, isLoading: anatomicalVolumeUrlIsLoading} =
  //   enhancedApi.endpoints.getApiScanProcessingTasksByIdSingleScanInputSourceUrl.useQuery(
  //     {
  //       id: props.step.tasks.find(v => v.taskTemplate.systemId === "ROI_REMOVAL_TASK")!.scanProcessingTask.id!,
  //       analysisTaskArgumentKey: 'anatomical_vti'
  //     },
  //     {
  //       refetchOnMountOrArgChange: 600
  //     });
  //
  // const {data: roiPolyDataBundleUrlData, isLoading: roiPolyDataBundleUrlIsLoading} =
  //   enhancedApi.endpoints.getApiScanProcessingTasksByIdSingleScanInputSourceUrl.useQuery(
  //     {
  //       id: props.step.tasks.find(v => v.taskTemplate.systemId === "ROI_REMOVAL_TASK")!.scanProcessingTask.id!,
  //       analysisTaskArgumentKey: 'polydata_bundle'
  //     },
  //     {
  //       refetchOnMountOrArgChange: 600
  //     });

  const {data: currentStepArtifactsData, isLoading: currentStepArtifactsIsLoading} =
    enhancedApi.endpoints.getApiScanAnalysisStepsByIdArtifactUrls.useQuery(
      {
        id: props.step.scanAnalysisStep.id!
      },
      {
        refetchOnMountOrArgChange: 600
      });

  const [doSaveRoisJsonText, saveRoisJsonTextResult] =
    enhancedApi.endpoints.postApiScanProcessingTasksByIdSingleStepOutputArtifactText.useMutation();

  const [doMarkStepComplete, markStepCompleteResult] =
    enhancedApi.endpoints.postApiScanAnalysisStepsByIdManuallyMarkComplete.useMutation();

  const [loadScanAnalysisVm] =
    enhancedApi.endpoints.getApiScanAnalysisViewModelsById.useLazyQuery();

  const [loadStepArtifacts] =
    enhancedApi.endpoints.getApiScanAnalysisStepsByIdArtifactUrls.useLazyQuery();

  const [loadImportStepArtifacts, {data: importStepArtifactsData, isLoading: importStepArtifactsIsLoading}] =
    enhancedApi.endpoints.getApiScanAnalysisStepsByIdArtifactUrls.useLazyQuery();

  const [loadLisStepArtifacts, {data: lisStepArtifactsData, isLoading: lisStepArtifactsIsLoading}] =
    enhancedApi.endpoints.getApiScanAnalysisStepsByIdArtifactUrls.useLazyQuery();

  useEffect(() => {
    if (!importStepArtifactsData && !importStepArtifactsIsLoading) {
      const importStepId = props.scanAnalysis.orderedScanAnalysisSteps
        .filter(x => x.stepTemplate.systemId === "IMPORT")
        .map(x => x.scanAnalysisStep.id!)
        .at(0);
      if (importStepId) {
        loadImportStepArtifacts({
          id: importStepId
        });
      }
    }
  }, [importStepArtifactsData, importStepArtifactsIsLoading]);

  useEffect(() => {
    if (!lisStepArtifactsData && !lisStepArtifactsIsLoading) {
      const lisStepId = props.scanAnalysis.orderedScanAnalysisSteps
        .filter(x => x.stepTemplate.systemId?.endsWith("_LIS"))
        .map(x => x.scanAnalysisStep.id!)
        .at(0);
      if (lisStepId) {
        loadLisStepArtifacts({
          id: lisStepId
        });
      } else {
        console.error("No LIS step found - unable to load LIS data")
      }
    }
  }, [lisStepArtifactsData, lisStepArtifactsIsLoading]);

  useEffect(() => {
    // If this step isn't complete, load ROIs from the previous step.
    // Otherwise, attempt to load ROIs from this step.
    const getRoisUrl = (): string | null => {
      if (props.step.scanAnalysisStep.complete) {
        // TODO: Step artifact VM needs a stable identifier to query on here.
        return !currentStepArtifactsIsLoading && currentStepArtifactsData
          ? currentStepArtifactsData!.find(v => v.fileName!.includes("rois.json"))!.temporaryUri!
          : null;
      } else {
        return !roisUrlIsLoading && roisUrlData
          ? roisUrlData!.temporaryUri!
          : null;
      }
    };

    // TODO: Move this into the redux state w/ slices, reducers, selectors, etc. -KAG20220614
    const roisUrl = getRoisUrl();
    if (roisUrl) {
      loadClientSideRoiViewModels(roisUrl)
        .then(
          v => {
            setRois(v);
          },
          e => {
            setToastText(`An error occurred while trying to load ROIs: ${e}`)
          });
    }
  }, [roisUrlData, roisUrlIsLoading, currentStepArtifactsData, currentStepArtifactsIsLoading])

  useEffect(() => {
    // If local state is saving, kick off saving the ROIs and completing the step
    if (!isSaving) return;

    if (saveRoisJsonTextResult.isUninitialized) {
      const roisJsonStr = JSON.stringify(mapToRawRoiDtos(rois!));
      doSaveRoisJsonText({
        id: props.step.tasks.find(v => v.taskTemplate.systemId === "ROI_REMOVAL_TASK")!.scanProcessingTask.id!,
        outputSystemId: 'FINAL_ROIS_JSON',
        body: roisJsonStr
      });
      return;
    } else if (saveRoisJsonTextResult.isError) {
      const errorMessage = getErrorMessageFromRtkMutationResult(saveRoisJsonTextResult);
      setToastText(`An error occurred while trying to save ROIs - ${errorMessage}`);
      setIsSaving(false);
      saveRoisJsonTextResult.reset();
      return;
    }

    if (!saveRoisJsonTextResult.isSuccess) return;

    if (markStepCompleteResult.isUninitialized) {
      const stepId = props.step.scanAnalysisStep.id!;
      doMarkStepComplete({
        id: stepId,
        scanAnalysisStepManualCompletionRequest: {
          stepId: stepId
        }
      });
      return;
    } else if (markStepCompleteResult.isError) {
      const errorMessage = getErrorMessageFromRtkMutationResult(markStepCompleteResult);
      setToastText(`An error occurred while trying to mark step complete - ${errorMessage}`);
      setIsSaving(false);
      saveRoisJsonTextResult.reset();
      markStepCompleteResult.reset();
      return;
    }

    if (!markStepCompleteResult.isSuccess) return;

    setToastText("Save ROIs successful!");
    loadScanAnalysisVm({
      id: props.step.scanAnalysisStep.analysisId!
    });
    loadStepArtifacts({
      id: props.step.scanAnalysisStep.id!
    });
    setIsSaving(false);
    saveRoisJsonTextResult.reset();
    markStepCompleteResult.reset();
  }, [isSaving, saveRoisJsonTextResult, markStepCompleteResult])

  //endregion

  //region -- Handlers --

  // TODO: Put handlers back after addressing stability issues with VTK lib -KAG20230713
  // /**
  //  * A callback that is invoked when the user selects a ROI in the 3D View.
  //  * NOTE: The 3D View supports single select, but the Details table supports multi-select.
  //  * @param id The ID of the ROI.
  //  */
  // const onRoiSelected = (id: string) => {
  //   setSelectedRoiId(id);
  //   onRoiSelectionChanged(id, true);
  // };

  /**
   * A callback that is invoked when the user changes the selection of a ROI in the Details table or 3D view.
   *
   * @param id The ID of the ROI
   * @param isSelected An explicit value to set.  If left unspecified, the existing value is toggled.
   */
  const onRoiSelectionChanged = (id: string, isSelected?: boolean) => {
    setRois(
      produce((draft) => {
        if (!draft) return;
        const roiToChange = draft.find(v => v.roiId === id);
        if (!roiToChange) {
          console.log(`Unable to change selection for ROI - cannot find ROI with ID '${id}'`);
          return;
        }
        roiToChange.isSelected = isSelected === undefined ? !roiToChange.isSelected : isSelected;
      })
    );
  };

  const onRoiLocationChanged = (id: string, location: string) => {
    setRois(
      produce((draft) => {
        if (!draft) return;
        const roiToChange = draft.find(v => v.roiId === id);
        if (!roiToChange) {
          console.log(`Unable to change location for ROI - cannot find ROI with ID '${id}'`);
          return;
        }
        roiToChange.anatomySegmentSysName = location;
      })
    );
  };

  const onDeleteRois = (roiIds: Array<string>) => {
    // Abort if ROIs aren't loaded yet
    if (!rois) return;

    // Get the ROIs from the ROI IDs
    const roisToDelete: Array<IClientSideRoiViewModel> = rois.filter(v => roiIds.includes(v.roiId));

    // Abort if we couldn't find all the ROIs
    if (roisToDelete.length !== roiIds.length) {
      setToastText(`Unable to delete ROIs - cannot find all ROIs with IDs '${roiIds.join(",")}'`);
      return;
    }

    // Raise confirmation dialog
    setConfirmDeleteRoiDialogOpen(true);
  }

  const onUndeleteRois = (roiIds: Array<string>) => {
    // Abort if ROIs aren't loaded yet
    if (!rois) return;

    // Get the ROIs from the ROI IDs
    const roisToUndelete: Array<IClientSideRoiViewModel> = rois.filter(v => roiIds.includes(v.roiId));

    // Abort if we couldn't find all the ROIs
    if (roisToUndelete.length !== roiIds.length) {
      setToastText(`Unable to undelete ROIs - cannot find all ROIs with IDs '${roiIds.join(",")}'`);
      return;
    }

    // Clear deleted flag and reason from ROIs
    setRois(
      produce((draft) => {
        if (!draft) return;
        roiIds.forEach(roiId => {
          const roiToUndelete = draft.find(v => v.roiId === roiId);
          if (!roiToUndelete) {
            setToastText(`Unable to un-delete ROI - cannot find ROI with ID '${roiId}'`);
            return;
          }
          roiToUndelete.isDeleted = false;
          roiToUndelete.deleteReason = null;
          roiToUndelete.isSelected = false;
        })
      })
    );
  }

  const onSaveRois = () => {
    setConfirmSaveRoisDialogOpen(true);
  }

  const handleConfirmSaveRois = () => {
    setConfirmSaveRoisDialogOpen(false);

    // Ensure all ROIs have a valid location before saving
    const validLocations = getRoiLocationsForReporting()
    let invalidLocationRois = (rois ?? []).filter(v => !v.isDeleted && !validLocations.includes(v.anatomySegmentSysName!));
    if (invalidLocationRois.length > 0) {
      let invalidLocationRoiNames = invalidLocationRois.map(v => v.anatomySegmentSysName).join(", ");
      toast(`Save ROIs failed - All ROIs must have a valid location. The followng locations are invalid: ${invalidLocationRoiNames}`, { type: "error" });
      return;
    }

    setIsSaving(true);
  }

  const handleCancelSaveRois = () => {
    setConfirmSaveRoisDialogOpen(false);
  }

  const handleCancelDeleteRoi = () => {
    setConfirmDeleteRoiDialogOpen(false);
  };

  const handleConfirmDeleteRoi = (result: IConfirmDeleteRoiDialogResult) => {
    setConfirmDeleteRoiDialogOpen(false);
    setRois(
      produce((draft) => {
        if (!draft) return;
        result.roiIds.forEach(roiId => {
          const roiToDelete = draft.find(v => v.roiId === roiId);
          if (!roiToDelete) {
            console.log(`Unable to delete ROI - cannot find ROI with ID '${roiId}'`);
            return;
          }
          roiToDelete.isDeleted = true;
          roiToDelete.deleteReason = result.deleteReason;
          roiToDelete.isSelected = false;
        });
      })
    );
  };

  const handleAllRoisSelected = (isSelected: boolean) => {
    setRois(
      produce((draft) => {
        if (!draft) return;
        draft.forEach(x => {
          x.isSelected = isSelected;
        });
      })
    );
  }

  //endregion

  const dataIsLoading =
    roisUrlIsLoading || rois === null ||
    // TODO: Put predicate conditions back after addressing stability issues with VTK lib -KAG20230713
    // anatomicalVolumeUrlIsLoading ||
    // roiPolyDataBundleUrlIsLoading ||
    importStepArtifactsIsLoading || !importStepArtifactsData ||
    lisStepArtifactsIsLoading || !lisStepArtifactsData;

  const isReadOnly = props.step.isAutomated
    ? props.step.isComplete
    : props.step.scanAnalysisStep.complete ?? false;

  const selectedRois: Array<IClientSideRoiViewModel> = (rois ?? [])
    .filter(x => x.isSelected);

  const selectedRoiIds: Array<string> = selectedRois.map(x => x.roiId);

  const canDeleteRois = selectedRois.length > 0 || isSaving;
  const canUndeleteRois = selectedRois.length > 0 || isSaving;

  const showSlbScore = (props.scanAnalysis.applicationTemplate.steps?.find(s => s.systemId === 'NAF_SLB')) !== undefined;

  // TODO: Put render 3D view back after addressing stability issues with VTK lib -KAG20230713
  // const renderWith3d = () => {
  //   return (
  //     <React.Fragment>
  //       {dataIsLoading
  //         ? <Grid container padding={1}>
  //           <Grid item xs={12}>
  //             <CircularProgress/>
  //           </Grid>
  //         </Grid>
  //         : <Grid container padding={1}>
  //           <Grid item xs={3}>
  //             <RoiRemoval3dView
  //               scanAnalysisId={props.step.scanAnalysisStep.analysisId!}
  //               rois={rois}
  //               anatomicalVolumeImageUrl={anatomicalVolumeUrlData!.temporaryUri!}
  //               roiPolyDataPackageUrl={roiPolyDataBundleUrlData!.temporaryUri!}
  //               cursorPosition={cursorPosition}
  //               onRoiSelected={(id) => onRoiSelected(id)}
  //             />
  //           </Grid>
  //           <Grid item xs={9}>
  //             <Grid container padding={1}>
  //               <Grid item xs={12}>
  //                 <Box sx={{marginLeft: "20px"}}>
  //                   <RoiDetailsTable
  //                     rois={rois}
  //                     showSlbScore={showSlbScore}
  //                     onRoiSelected={(roi) => onRoiSelectionChanged(roi.roiId)}
  //                     onAllRoisSelected={handleAllRoisSelected}
  //                   />
  //                 </Box>
  //                 <Box sx={{marginLeft: "20px", marginTop: "20px"}}>
  //                   <Box data-cy="single-scan-step-artifacts" style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
  //                     {importStepArtifactsData
  //                       .filter(x => x.fileName?.includes("ctData.nrrd") || x.fileName?.includes("ptData.nrrd"))
  //                       .map(x => (
  //                         <React.Fragment>
  //                           <Link href={x.temporaryUri!} underline="hover">{x.relativePath!}</Link>
  //                         </React.Fragment>
  //                       ))
  //                     }
  //                     {
  //                       lisStepArtifactsData
  //                         .filter(x => x.fileName?.includes("labelled_rois.nrrd"))
  //                         .map(x => (
  //                           <React.Fragment>
  //                             <Link href={x.temporaryUri!} underline="hover">{x.relativePath!}</Link>
  //                           </React.Fragment>
  //                         ))
  //                     }
  //                   </Box>
  //                 </Box>
  //                 {isReadOnly
  //                   ? null
  //                   : <Box
  //                     display={"flex"}
  //                     justifyContent={"flex-end"}
  //                     alignItems={"flex-end"}
  //                     sx={{
  //                       marginLeft: "20px",
  //                       height: "60px",
  //                       border: "0px solid black",
  //                       padding: "8px"
  //                     }}
  //                   >
  //                     <Button
  //                       variant={"contained"}
  //                       disabled={!canDeleteRois}
  //                       onClick={() => onDeleteRois(selectedRoiIds)}
  //                       sx={{marginLeft: "10px"}}
  //                     >
  //                       Delete ROIs
  //                     </Button>
  //                     <Button
  //                       variant={"contained"}
  //                       disabled={!canUndeleteRois}
  //                       onClick={() => onUndeleteRois(selectedRoiIds)}
  //                       sx={{marginLeft: "10px"}}
  //                     >
  //                       Undelete ROIs
  //                     </Button>
  //                     <Button
  //                       variant={"contained"}
  //                       disabled={isSaving}
  //                       onClick={() => onSaveRois()}
  //                       sx={{marginLeft: "10px"}}
  //                       endIcon={isSaving ? <CircularProgress size={18} /> : null}
  //                     >
  //                       Save ROIs
  //                     </Button>
  //                   </Box>
  //                 }
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       }
  //       {dataIsLoading
  //         ? null
  //         : <ConfirmDeleteRoiDialog
  //           roiIds={selectedRoiIds}
  //           open={confirmDeleteRoiDialogOpen}
  //           onConfirm={handleConfirmDeleteRoi}
  //           onClose={handleCancelDeleteRoi}
  //         />
  //       }
  //       {dataIsLoading
  //         ? null
  //         : <ConfirmationDialog
  //           open={confirmSaveRoisDialogOpen}
  //           onCancel={handleCancelSaveRois}
  //           onConfirm={handleConfirmSaveRois}
  //           text={"Are you sure you want to save ROIs and complete this step?"}
  //           title={"Confirm Save ROIs"}
  //           confirmButtonLabel={"Confirm"}
  //         />
  //       }
  //       {dataIsLoading
  //         ? null
  //         : <Toast
  //           open={toastText !== null}
  //           onClose={() => setToastText(null)}
  //           text={toastText ?? ""}
  //         />
  //       }
  //     </React.Fragment>
  //   );
  // };

  const renderWithout3d = () => {
    return (
      <React.Fragment>
        {dataIsLoading
          ? <Grid container padding={1}>
            <Grid item xs={12}>
              <CircularProgress/>
            </Grid>
          </Grid>
          : <Grid container padding={1}>
            <Grid item xs={12}>
              <Grid container padding={1}>
                <Grid item xs={12}>
                  <Box sx={{marginLeft: "20px"}}>
                    <RoiDetailsTable
                      rois={rois}
                      showSlbScore={showSlbScore}
                      onRoiSelected={(roi) => onRoiSelectionChanged(roi.roiId)}
                      onAllRoisSelected={handleAllRoisSelected}
                      onRoiLocationChanged={(roi, location) => onRoiLocationChanged(roi.roiId, location)}
                      isReadOnly={isReadOnly}
                    />
                  </Box>
                  <Box sx={{marginLeft: "20px", marginTop: "20px"}}>
                    <Box data-cy="single-scan-step-artifacts" style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                      {importStepArtifactsData
                        .filter(x => x.fileName?.includes("ctData.nrrd") || x.fileName?.includes("ptData.nrrd") || x.fileName?.includes("petData.nrrd"))
                        .map(x => (
                          <React.Fragment key={x.temporaryUri!}>
                            <Link href={x.temporaryUri!} underline="hover">{x.relativePath!}</Link>
                          </React.Fragment>
                        ))
                      }
                      {
                        lisStepArtifactsData
                          .filter(x => x.fileName?.includes("labelled_rois.nrrd"))
                          .map(x => (
                            <React.Fragment key={x.temporaryUri!}>
                              <Link href={x.temporaryUri!} underline="hover">{x.relativePath!}</Link>
                            </React.Fragment>
                          ))
                      }
                    </Box>
                  </Box>
                  {isReadOnly
                    ? null
                    : <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                      sx={{
                        marginLeft: "20px",
                        height: "60px",
                        border: "0px solid black",
                        padding: "8px"
                      }}
                    >
                      <Button
                        variant={"contained"}
                        disabled={!canDeleteRois}
                        onClick={() => onDeleteRois(selectedRoiIds)}
                        sx={{marginLeft: "10px"}}
                      >
                        Delete ROIs
                      </Button>
                      <Button
                        variant={"contained"}
                        disabled={!canUndeleteRois}
                        onClick={() => onUndeleteRois(selectedRoiIds)}
                        sx={{marginLeft: "10px"}}
                      >
                        Undelete ROIs
                      </Button>
                      <Button
                        variant={"contained"}
                        disabled={isSaving}
                        onClick={() => onSaveRois()}
                        sx={{marginLeft: "10px"}}
                        endIcon={isSaving ? <CircularProgress size={18} /> : null}
                      >
                        Save ROIs
                      </Button>
                    </Box>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        {dataIsLoading
          ? null
          : <ConfirmDeleteRoiDialog
            roiIds={selectedRoiIds}
            open={confirmDeleteRoiDialogOpen}
            onConfirm={handleConfirmDeleteRoi}
            onClose={handleCancelDeleteRoi}
          />
        }
        {dataIsLoading
          ? null
          : <ConfirmationDialog
            open={confirmSaveRoisDialogOpen}
            onCancel={handleCancelSaveRois}
            onConfirm={handleConfirmSaveRois}
            text={"Are you sure you want to save ROIs and complete this step?"}
            title={"Confirm Save ROIs"}
            confirmButtonLabel={"Confirm"}
          />
        }
        {dataIsLoading
          ? null
          : <Toast
            open={toastText !== null}
            onClose={() => setToastText(null)}
            text={toastText ?? ""}
          />
        }
      </React.Fragment>
    );
  }

  return renderWithout3d();
};
