import * as React from "react";
import {
  Typography,
  Backdrop,
  CircularProgress,
  useTheme,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Link, Grid, Divider
} from "@mui/material";
import { ArtifactSideLoadHistoryViewModel, ScanAnalysisStepArtifactViewModel } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { AiqTooltip } from "../common/AiqToolTip";
import { dateFormatWithTime, getFormattedDateFromString } from "../../app/utilities/dateUtilities";

interface IJsonArtifactPreviewPopupProps {
  artifact: ScanAnalysisStepArtifactViewModel;
  onClose: () => void;
}

/**
 * This popup shows a formatted read-only view of the Side-load history of an artifact
 */
export const SideLoadHistoryPopup: React.FunctionComponent<IJsonArtifactPreviewPopupProps> = (props) => {
  const theme = useTheme();
  const { data, isLoading, error } = enhancedApi.endpoints.getApiScanProcessingTasksByIdArtifactSideloadHistory.useQuery(
  { id: props.artifact.taskId!, outputSystemId: props.artifact.systemId!, taskArgumentIndex: props.artifact.templateArgumentIndex }
    );

  const titleStyle = {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.secondary.light,
  }

  const headerStyle = {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    width: "100%",
  }
  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xl"
      onClose={() => props.onClose()}
      data-cy="SideLoadHistoryPopup"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={titleStyle}
        data-cy="SideLoadHistoryTitle"
      >
        Side-load History: {props.artifact.fileName}
      </DialogTitle>
      <DialogContent data-cy="SideLoadHistoryContents">
        <Box sx={{ minHeight: "200px", padding: "10px" }}>
          <Backdrop sx={{ zIndex: theme.zIndex.drawer + 1, color: "#fff" }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>

          {(error && (<Typography sx={headerStyle}>Failed to load Side-load history</Typography>))}
          {(data as ArtifactSideLoadHistoryViewModel[])?.length == 0 && (<Typography sx={headerStyle}>This file has no Side-load history</Typography>)}
          {
            (data as ArtifactSideLoadHistoryViewModel[])?.length > 0 &&
            (data as ArtifactSideLoadHistoryViewModel[]).map(entry => (
            <Box>
              <Divider/>
              <Grid container spacing={0} sx={{mt: 1, mb: 1}}>
                {entry.sideLoadDateTime && entry.sideLoadDateTime && entry.temporaryUri &&
                  (
                  <>
                    <Grid item xs={2}>
                      <Typography sx={headerStyle}>Time:</Typography><Typography>{getFormattedDateFromString(entry.sideLoadDateTime, dateFormatWithTime)}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={headerStyle}>Reason:</Typography><Typography>{entry.sideLoadReason}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={headerStyle}>User:</Typography><Typography>{entry.sideLoadUser}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={headerStyle}>Back-up File:</Typography>
                      <Typography>
                        <AiqTooltip title={entry.relativePath ?? ""}>
                          <Link href={entry.temporaryUri} underline="hover">
                            Link to prior version
                          </Link>
                        </AiqTooltip>
                      </Typography>
                    </Grid>
                  </>
                )}
                {!(entry.sideLoadDateTime && entry.sideLoadDateTime && entry.temporaryUri) && (
                  <Grid item xs={2}>
                    <Typography sx={headerStyle}>Failed to load side-load history</Typography>
                  </Grid>
                )}
              </Grid>
              <Divider/>
            </Box>
            ))}

        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
          variant="contained"
          color="secondary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
