import {
  ScanAnalysisViewModel,
  InterscanAnalysisViewModel,
  SingleScanApplicationTaskTemplateApplicationStepTemplate,
  InterscanApplication,
  SingleScanApplication,
  InterscanApplicationTaskTemplateApplicationStepTemplate,
  Study
} from "../../../app/api/aiq-api";
import { ClientSideCompoundInterscanAnalysisStepViewModel } from "./ClientSideCompoundInterscanAnalysisStepViewModel";
import { ClientSideInterscanAnalysisStepViewModel } from "./ClientSideInterscanAnalysisStepViewModel";
import { ClientSideSingleScanAnalysisStepViewModel } from "./ClientSideSingleScanAnalysisStepViewModel";

/**
 * A base class for a client-side view model for displaying UIs for an Interscan Analysis or a Single Scan Analysis.
 */
export abstract class ClientSideBaseAnalysisViewModel {
  protected constructor(backendViewModel: InterscanAnalysisViewModel | ScanAnalysisViewModel) {
    this.checkRequiredFieldsForNull(backendViewModel);
    this.study = backendViewModel.study!;
  }

  orderedScanAnalysisSteps!: Array<ClientSideSingleScanAnalysisStepViewModel | ClientSideInterscanAnalysisStepViewModel | ClientSideCompoundInterscanAnalysisStepViewModel>;
  study!: Study;
  applicationTemplate!: InterscanApplication | SingleScanApplication;

  /**
   * Whether this Scan Analysis is in a state that would allow it to be moved
   * to its subsequent automated step, if any
   */
  get canMoveToNextStep(): boolean {
    return (
      this.successorStepTemplate !== undefined &&
      this.successorStepTemplate.automated == true &&
      this.currentStepComplete &&
      !this.currentStepHasError
    );
  }

  /**
   * The most recent Scan Analysis Step associated with this Scan Analysis, per the Component sequence in its Application
   */
  get currentStep(): ClientSideInterscanAnalysisStepViewModel | ClientSideSingleScanAnalysisStepViewModel | ClientSideCompoundInterscanAnalysisStepViewModel {
    return this.orderedScanAnalysisSteps[this.orderedScanAnalysisSteps.length - 1];
  }

  /**
   * Indicates whether the processing for the Scan Analysis is in an error state
   */
  get currentStepHasError(): boolean {
    return this.currentStep.hasErrors ?? false;
  }

  /**
   * Indicates whether the current step of the scan analysis has warnings
   */
  get currentStepHasWarnings(): boolean {
    return this.currentStep.hasWarnings ?? false;
  }

  /**
   * Indicates whether the scan analysis has warnings on the current step or any step before it
   */
  get hasWarningsOnOrBeforeCurrentStep(): boolean {
    const index = this.orderedScanAnalysisSteps
      .findIndex(s => s.scanAnalysisStep.id === this.currentStep.scanAnalysisStep.id);

    if (index === -1) return false;

    const stepsOfInterest = this.orderedScanAnalysisSteps.slice(0, index + 1);

    return stepsOfInterest.some(s => s.hasWarnings);
  }

  /**
   * Indicates whether the processing for the Scan Analysis is awaiting processing
   */
  get currentStepIsWaiting(): boolean {
    return this.currentStep.isWaiting ?? false;
  }
  /**
   * The date/time of the most recent Status update for the current Step, if any
   */
  get currentStepTimeOfLastStatusUpdate(): string | undefined {
    return this.currentStep.timeOfLastStatusUpdate ?? undefined;
  }

  /**
   * How far along the current automated Step is, as a percent.
   */
  get currentStepPercentComplete(): number {
    return this.currentStep.averagePercentComplete ?? 0;
  }

  get isFinished(): boolean {
    let lastStep = this.applicationTemplate!.steps![this.applicationTemplate!.steps!.length - 1];
    return this.currentStep.scanAnalysisStep.applicationStepSystemId == lastStep.systemId && this.currentStepComplete;
  }

  /**
   * Whether or not the current Step is in progress
   */
  get currentStepInProgress(): boolean {
    return this.currentStep.isInProgress ?? false;
  }

  /**
   * Whether or not the current Step is complete
   */
  get currentStepComplete(): boolean {
    return this.currentStep.isComplete ?? false;
  }

  /**
   * The Scan Analysis Step Template that is the successor of the current Step, if any
   */
  get successorStepTemplate():
    | InterscanApplicationTaskTemplateApplicationStepTemplate
    | SingleScanApplicationTaskTemplateApplicationStepTemplate
    | undefined {
    return this.applicationTemplate.steps!.length > this.currentStep.stepSequence! + 1
      ? this.applicationTemplate.steps![this.currentStep.stepSequence! + 1]
      : undefined;
  }

  /**
   * A display string to describe the status of the current Step
   */
  get statusDisplayString(): string {
    return this.currentStep.statusDisplayString;
  }


  canTransitionToStep(targetStepSystemId: string): boolean {

    // Can't transition to the step it's already at
    if (this.currentStep.scanAnalysisStep?.applicationStepSystemId === targetStepSystemId) {
      return false;
    }

    // Can transition to the next automated step for this analysis
    if (
      this.successorStepTemplate?.systemId === targetStepSystemId &&
      this.successorStepTemplate?.automated &&
      (this.currentStep.isComplete || !this.currentStep.isAutomated)
    ) {
      return true;
    }

    // Can transition backwards to a previous step
    const targetStepIndex = this.applicationTemplate.steps!.findIndex((s) => s.systemId === targetStepSystemId);
    // noinspection RedundantIfStatementJS
    if (targetStepIndex < this.currentStep.stepSequence!) {
      return true;
    }

    return false;
  }

  protected abstract checkRequiredFieldsForNull(backendViewModel: InterscanAnalysisViewModel): void;
}
